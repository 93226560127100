import { useSearchBox, useInstantSearch, useClearRefinements, useSortBy } from 'react-instantsearch';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

export const useSearchProducts = () => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

  const location = useLocation();
  const { updateBreadcrumbs } = useBreadcrumbs();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const sortOptions = [
    {
      label: 'Default',
      value: process.env.REACT_APP_ALGOLIA_INDEX!,
      default: true,
    },
    {
      label: 'Price: Low to High',
      value: process.env.REACT_APP_ALGOLIA_INDEX_PRICE_ASC!,
    },
    {
      label: 'Price: High to Low',
      value: process.env.REACT_APP_ALGOLIA_INDEX_PRICE_DESC!,
    },
    {
      label: 'Name: AtoZ',
      value: process.env.REACT_APP_ALGOLIA_INDEX_NAME_ASC!,
    },
    {
      label: 'Name: ZtoA',
      value: process.env.REACT_APP_ALGOLIA_INDEX_NAME_DESC!,
    },
  ];

  const { results } = useInstantSearch();
  const { refine: clearRefine } = useClearRefinements();
  const { refine: setQuery } = useSearchBox();
  const {
    options,
    refine: refineSortBy,
    canRefine,
    currentRefinement,
  } = useSortBy({
    items: sortOptions,
  });

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Search Results', link: '/products' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.searchName) {
      setQuery(location.state?.searchName);
    } else if (search) {
      setQuery(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setQuery]);

  return {
    results,
    clearRefine,
    location,
    search,
    filterDrawerOpen,
    setFilterDrawerOpen,
    isTouchDevice,
    options,
    refineSortBy,
    canRefine,
    currentRefinement,
  };
};
