import {
  NopCheckboxFilter,
  NopCheckboxFilterSkeleton,
  NopHits,
  NopHitsSkeleton,
  NopSlider,
  NopSliderSkeleton,
  NopSort,
  NopPageSize,
  NopResultsHeader,
  NopResultsHeaderSkeleton,
} from 'components';
import { Button, ButtonSkeleton, Pagination, Typography, TypographySkeleton } from 'design-system';
import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { CategorySliderSkeleton } from './components/category-slider-skeleton';
import { Box, Divider, Grid, useMediaQuery } from '@mui/material';
import { CategorySlider } from './components/category-slider';
import { Navigate } from 'react-router-dom';
import { useCategory } from './useCategory';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

const CategorySkeleton = () => {
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  return (
    <>
      <Grid container spacing={{ xs: 0, md: 3 }}>
        {!isTouchDevice && (
          <Grid item xs={12} md={2.5}>
            <Box className={styles.filter__box}>
              <CategoryDetailsSkeleton />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={9.5}>
          <CategorySliderSkeleton />
          <CategoryProductsSkeleton />
        </Grid>
      </Grid>
    </>
  );
};

const CategoryDetailsSkeleton = () => {
  return (
    <>
      <TypographySkeleton variant="h5" className={classNames(styles.section__subtitle, 'w-1/2')} />
      <Divider className="mb-6" />
      <TypographySkeleton variant="subtitle1" className="mb-3" />
      <NopSliderSkeleton min={0} max={10000} value={[0, 10000]} />
      <Divider className="my-8" />
      <NopCheckboxFilterSkeleton />
      <NopCheckboxFilterSkeleton />
      <ButtonSkeleton className="w-1/2 h-10" />
    </>
  );
};

const CategoryProductsSkeleton = () => {
  return (
    <>
      <NopResultsHeaderSkeleton />
      <NopHitsSkeleton />
    </>
  );
};

export const Category = () => {
  const {
    categoryId,
    categoryProductsResponse,
    categoryDetails,
    handleFilterRequest,
    isCategoryDetailsFetching,
    isCategoryProductsFetching,
    totalItems,
    totalPages,
    pageSize,
    pageSizeOptions,
    manufacturerIds,
    setPageSize,
    orderBy,
    availableSortOptions,
    setOrderBy,
    pageNumber,
    onChangePage,
    priceRange,
    onChangePriceRange,
    manufacturerOptions,
    onChangeManufacturer,
    isTouchDevice,
    isCategoryDetailsError,
    isCategoryProductsError,
  } = useCategory();

  if (isCategoryProductsFetching && isCategoryDetailsFetching) return <CategorySkeleton />;
  if (isCategoryDetailsError || isCategoryProductsError) return <Navigate to="/error" />;

  return (
    <>
      <Helmet>
        <title>{categoryDetails?.categoryModelDto.metaTitle || categoryDetails?.categoryModelDto.name}</title>
        <meta
          name="description"
          content={
            categoryDetails?.categoryModelDto.metaDescription ? categoryDetails?.categoryModelDto.metaDescription : ''
          }
        />
        <meta
          name="keywords"
          content={categoryDetails?.categoryModelDto.metaKeywords ? categoryDetails?.categoryModelDto.metaKeywords : ''}
        />
      </Helmet>

      <Grid container spacing={{ xs: 0, md: 3 }}>
        {!isTouchDevice && (
          <Grid item xs={12} md={2.5}>
            <Box className={styles.filter__box}>
              {!isCategoryDetailsFetching && (
                <>
                  <Typography variant="h5" component="h5" className={styles.section__subtitle}>
                    Filter Items
                  </Typography>

                  <Divider className="mb-6" />

                  <Typography variant="subtitle1" className="text-darkGray capitalize mb-3">
                    Price Range
                  </Typography>

                  <NopSlider
                    min={0}
                    max={
                      categoryProductsResponse?.catalogProductsModel?.priceRangeFilter?.availablePriceRange.to || 10000
                    }
                    value={priceRange}
                    onChange={onChangePriceRange}
                  />

                  <Divider className="my-8" />

                  <NopCheckboxFilter
                    manufacturerOptions={manufacturerOptions}
                    onChangeManufacturer={onChangeManufacturer}
                    manufacturerIds={manufacturerIds}
                  />

                  <Button
                    size="small"
                    className="mt-3 hover:bg-brand2"
                    startIcon={<FilterIcon stroke="var(--white)" />}
                    onClick={handleFilterRequest}
                    label="Filter"
                  />
                </>
              )}

              {isCategoryDetailsFetching && <CategorySliderSkeleton />}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} lg={9.5}>
          {!isCategoryDetailsFetching && <CategorySlider categoryDetails={categoryDetails} />}
          {isCategoryDetailsFetching && <CategorySlider categoryDetails={categoryDetails} />}
          {!isCategoryProductsFetching && (
            <>
              <NopResultsHeader
                itemsCount={totalItems}
                pageSizeComponent={
                  <NopPageSize
                    key={`category-${categoryId}-page-size`}
                    pageSize={pageSize.toString()}
                    pageSizeOptions={pageSizeOptions}
                    setPageSize={setPageSize}
                  />
                }
                sortComponent={
                  <NopSort
                    key={`category-${categoryId}-sort`}
                    currentValue={orderBy}
                    availableSortOptions={availableSortOptions}
                    setOrderBy={setOrderBy}
                  />
                }
                manufacturerOptions={manufacturerOptions}
                onChangeManufacturer={onChangeManufacturer}
                onFilter={handleFilterRequest}
                categoryProductsResponse={categoryProductsResponse}
                priceRange={priceRange}
                setPriceRange={onChangePriceRange}
              />

              <NopHits hits={categoryProductsResponse?.catalogProductsModel?.products} />

              {categoryProductsResponse && categoryProductsResponse.catalogProductsModel.totalItems > 0 && (
                <Box className={isTouchDevice ? 'mt-6 mb-12' : 'my-[60px]'}>
                  <Pagination
                    key={`category-${categoryId}-pagination`}
                    count={totalPages}
                    page={pageNumber}
                    handleChangePage={onChangePage}
                  />
                </Box>
              )}
            </>
          )}

          {isCategoryProductsFetching && <CategoryProductsSkeleton />}
        </Grid>
      </Grid>
    </>
  );
};
