import { VendorCardSkeleton, TypographySkeleton } from 'design-system';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

export const TopVendorsSkeleton = () => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      <Box className="flex justify-between">
        <TypographySkeleton variant="h3" className="w-1/3 mb-4 max-sm:mb-3" />
        <TypographySkeleton variant="h3" className="w-1/6 mb-4 max-sm:mb-3" />
      </Box>

      <Grid
        container
        spacing={isDownMd ? 1.5 : 3}
        wrap="nowrap"
        sx={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
        paddingBlock={'4px'}
      >
        {Array(8)
          .fill(null)
          .map((_, idx) => (
            <Grid item key={`top-vendors-skeleton-item-${idx}`}>
              <VendorCardSkeleton />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
