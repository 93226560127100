import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { LargeCategoryCard } from '../large-category-card';
import { FreeMode, Navigation } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography } from 'design-system';
import { useRef } from 'react';

type Props = {
  categories: CategorySimpleModelDto[] | undefined;
};

export const CategoryBestSeller = ({ categories }: Props) => {
  const sliderRef = useRef<SwiperClass>();
  const navigate = useNavigate();
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const bestSellerCategoriesSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: isDownMd ? 12 : 24,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.best-seller-category-swiper.custom-swiper-button-prev',
      nextEl: '.best-seller-category-swiper.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  if (!categories) return <></>;

  return (
    <>
      {categories.length >= 3 && (
        <>
          <Box className="flex justify-between items-center mb-4 max-sm:mb-3">
            <Typography variant="h3" component="h3" className="text-darkGray">
              Categories
            </Typography>

            <Button
              size="small"
              variant="text"
              color="inherit"
              className="text-midDarkGray"
              onClick={() => navigate('/all-categories')}
              label="View all"
            />
          </Box>

          <div className="swiper-with-arrows">
            <div
              className="best-seller-category-swiper custom-swiper-button-prev"
              onClick={() => sliderRef?.current?.slidePrev()}
            >
              <CaretRight />
            </div>

            <Swiper {...bestSellerCategoriesSwiperProps}>
              {categories.map(category => (
                <SwiperSlide
                  style={{ width: 'auto', padding: '2px' }}
                  key={`best-selling-category-slide-${category?.id}`}
                >
                  <Link
                    key={`category-#${category.id}`}
                    to={`/category/${category.id}/${category.seName}`}
                    state={{ fromSearch: false }}
                  >
                    <LargeCategoryCard category={category} />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>

            <div
              className="best-seller-category-swiper custom-swiper-button-next"
              onClick={() => sliderRef?.current?.slideNext()}
            >
              <CaretRight />
            </div>
          </div>
        </>
      )}
    </>
  );
};
