import { acceptQuotation, getBuyerRfqQuotations, getVendorById } from 'services/orchestrator.service';
import { QuotationsResponse } from 'services/dtos/quotation/quotations.response';
import { QuotationObj } from 'services/types/quotation/quotationObj.type';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { rejectQuotation } from 'services/orchestrator.service';
import { countNewQuotationStatus, sendEventToGTM } from 'utils';
import { RfqStatusEnum } from 'services/types/generic/enums';
import { useNavigate, useParams } from 'react-router-dom';
import { RFQ } from 'services/types/generic/rfq-entity';
import { useMediaQuery, useTheme } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthToken } from 'hooks';
import * as Yup from 'yup';

type Props = {
  quotations: QuotationsResponse;
  rfqDetails: RFQ;
};

export const useMyQuotation = ({ quotations, rfqDetails }: Props) => {
  const { id } = useParams();
  const { token } = useAuthToken();
  const { handleContactSeller, currentChannelUrl } = useMessageCenterContext();
  const navigate = useNavigate();
  const [quotationsFinal, setQuotationsFinal] = useState<QuotationsResponse>(quotations);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [vendorsQuotations, setVendorsQuotations] = useState<QuotationObj[][]>();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState<QuotationObj>();
  const [contactSellerClicked, setContactSellerClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isQuotationExpanded, setIsQuotationExpanded] = useState<boolean>(true);
  const [newQuotationsCount, setNewQuotationsCount] = useState<number>();
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const formSchema = Yup.object().shape({
    reason: Yup.string().required('This field is required'),
  });

  const {
    getValues,
    reset,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    setNewQuotationsCount(countNewQuotationStatus({ rfqDetails, quotations }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rfqDetails.status === RfqStatusEnum.Closed) setNewQuotationsCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqDetails]);

  useEffect(() => {
    getFinalVendorQuotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationsFinal]);

  useEffect(() => {
    if (isDownMd && currentChannelUrl !== '' && contactSellerClicked) {
      navigate(`/channels/${currentChannelUrl}`);
      setContactSellerClicked(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl, contactSellerClicked]);

  const fetchQuotationsData = () => {
    if (token && id) {
      getBuyerRfqQuotations(token, id).then(({ data: quotations }) => {
        setQuotationsFinal(quotations);
        setLoading(false);
        setNewQuotationsCount(countNewQuotationStatus({ rfqDetails, quotations }));
      });
    }
  };

  const getFinalVendorQuotations = () => {
    let allValues: QuotationObj[][] = [];
    if (quotationsFinal)
      for (const key in quotationsFinal) {
        if (Object.hasOwnProperty.call(quotationsFinal, key)) {
          const values: QuotationObj[] = quotationsFinal[key];
          allValues.push(values);
        }
      }
    setVendorsQuotations(allValues);
  };

  const onAccept = (QuotationObj: QuotationObj) => {
    setSelectedQuotation(QuotationObj);
    setAcceptModalOpen(true);
  };

  const handleAcceptQuotation = async () => {
    if (selectedQuotation && token) {
      setLoading(true);
      await acceptQuotation(token, selectedQuotation.quotationId);
      sendEventToGTM('accept_quotation', {
        rfq_short_id: rfqDetails.shortID,
        quotation_short_id: selectedQuotation.quotationShortId,
      });
      setAcceptModalOpen(false);
      fetchQuotationsData();
      countNewQuotationStatus({ rfqDetails, quotations });
    }
  };

  const onReject = (QuotationObj: QuotationObj) => {
    setSelectedQuotation(QuotationObj);
    setRejectModalOpen(true);
    fetchQuotationsData();
  };

  const onNegotiate = async (vendorQuotation: QuotationObj) => {
    setContactSellerClicked(true);
    if (token) {
      const { data: vendor } = await getVendorById(+vendorQuotation.vendorID);
      handleContactSeller({
        message: `Hi, I need to negotiate in this Quotation with number "${vendorQuotation.quotationShortId}" you submitted.`,
        vendorName: vendor.name!,
        vendorRepresentativeEmail: vendor.authorizedEmails!,
        vendorId: vendor.id,
      });

      sendEventToGTM('quotation_negotiate', {
        rfq_short_id: rfqDetails.shortID,
        quotation_short_id: vendorQuotation.quotationShortId,
        vendor_name: vendorQuotation.createdBy.name,
      });
    }
  };

  const handleRejectQuotation = async () => {
    if (selectedQuotation && token && isValid) {
      setLoading(true);
      const body = getValues();
      await rejectQuotation(token!, selectedQuotation?.quotationId!, body);
      reset();
      sendEventToGTM('reject_quotation', {
        rfq_short_id: rfqDetails.shortID,
        quotation_short_id: selectedQuotation.quotationShortId,
      });
      setRejectModalOpen(false);
      fetchQuotationsData();
    }
  };
  const handleSecondaryClickQuotation = async () => {
    reset();
    setRejectModalOpen(false);
  };

  return {
    vendorsQuotations,
    acceptModalOpen,
    setAcceptModalOpen,
    rejectModalOpen,
    onAccept,
    onReject,
    onNegotiate,
    handleAcceptQuotation,
    loading,
    handleRejectQuotation,
    handleSecondaryClickQuotation,
    control,
    isValid,
    newQuotationsCount,
    isQuotationExpanded,
    setIsQuotationExpanded,
    selectedQuotation,
  };
};
