import { Typography } from 'src/components/typographies/typography';
import { Box, BoxProps } from '@mui/material';
import { HTMLAttributes } from 'react';

type StatusChipProps = {
  status: string;
  bubble?: number;
} & HTMLAttributes<HTMLDivElement> &
  BoxProps;

export const StatusChip = ({ status, bubble, ...props }: StatusChipProps) => {
  let boxStyle;
  let bubbleStyle;
  let textStyle;

  switch (status) {
    case 'Ready':
    case 'New':
      boxStyle = 'bg-[#ff993333]';
      textStyle = 'text-deepYellow';
      break;
    case 'Closed':
    case 'Lost':
      boxStyle = 'bg-[#ff00001a]';
      textStyle = 'text-red';
      break;
    case 'Active':
    case 'Won':
      boxStyle = 'bg-[#3bb87d1a]';
      bubbleStyle = 'bg-[#3bb87d] text-white';
      textStyle = 'text-green';
      break;
    case 'Expired':
      boxStyle = 'bg-[#e4e4e480]';
      textStyle = 'text-midLightGray';
      break;
    default:
      break;
  }

  return (
    <Box className={`flex items-center justify-center py-2 rounded-lg ${boxStyle} ${props?.className}`} {...props}>
      <Typography variant="caption" className={`text-center font-bold ${textStyle}`}>
        {status}
      </Typography>

      {bubble && (
        <Box
          className={`flex items-center justify-center w-auto h-[18px] py-[2px] px-[6px] ms-1 rounded-full text-xs ${bubbleStyle}`}
        >
          {bubble}
        </Box>
      )}
    </Box>
  );
};
