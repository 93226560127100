import { ProductDetailsModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { getUnitShort } from 'design-system';

export const getUnit = (productDetailsModel: ProductDetailsModelDto) => {
  let unit: string = '';
  if (productDetailsModel.unit) {
    unit = `/ ${productDetailsModel.unit}`;
    if (getUnitShort(productDetailsModel.unit)) {
      unit = unit + ` (${getUnitShort(productDetailsModel.unit)})`;
    }
  }
  return unit;
};
