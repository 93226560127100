import { getSampleDetails } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthToken } from 'hooks';
import { getData } from 'utils';

export const useRequestSampleDetails = () => {
  const { id } = useParams();
  const { token } = useAuthToken();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const [isMainHovered, setIsMainHovered] = useState<boolean>(false);

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  // Get Sample Details
  const { data: requestSampleDetails, isError: isRequestSampleDetailsError } = useQuery({
    queryKey: ['requestSampleDetails', token, id],
    queryFn: getData(() => getSampleDetails(token!, id!)),
    enabled: !!(token && id),
  });

  if (isRequestSampleDetailsError) {
    navigate('/error');
  }

  useEffect(() => {
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestSampleDetails && requestSampleDetails?.shortID) {
      updateBreadcrumbs([
        { label: 'Manage Sample List', link: '/request-sample' },
        {
          label: `Sample #${requestSampleDetails?.shortID}`,
          link: `/request-sample/details/${id}`,
        },
      ]);
    }
  }, [id, requestSampleDetails, updateBreadcrumbs]);

  return {
    id,
    requestSampleDetails,

    isTouchDevice,
    isMainHovered,
    setIsMainHovered,
  };
};
