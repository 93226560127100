import { BottomNavigation, Footer as FooterComponent } from 'components';
import { useLoading } from 'contexts/loadingContext';
import { MessageCenterPopup } from 'components';
import { useMediaQuery } from '@mui/material';

export const FooterHandler = ({ hasFooter }: { hasFooter: boolean }) => {
  const { isLoading } = useLoading();
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  return (
    <>
      {!isLoading && (
        <footer id="footer-box">
          {hasFooter && <FooterComponent />}
          <BottomNavigation />
          {!isTouchDevice && <MessageCenterPopup />}
        </footer>
      )}
    </>
  );
};
