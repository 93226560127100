import { getCategoryById, getCategoryProducts } from 'services/orchestrator.service';
import { BreadcrumbItem, useBreadcrumbs } from 'contexts/breadcrumbContext';
import { SelectListItemDto } from 'otl-codegen/dist/marketplace-frontend';
import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getData, sendEventToGTM } from 'utils';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';

export const useCategory = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { categoryId, seName } = useParams();

  const [pageSize, setPageSize] = useState<number>(40);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<number>(5);
  const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
  const [manufacturerOptions, setManufacturerOptions] = useState<SelectListItemDto[] | null>();
  const [manufacturerIds, setManufacturerIds] = useState<number[]>([]);

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const pageSizeOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: true,
      text: '40',
      value: '40',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '60',
      value: '60',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '100',
      value: '100',
    },
  ];
  const availableSortOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: A to Z',
      value: '5',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: Z to A',
      value: '6',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: Low to High',
      value: '10',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: High to Low',
      value: '11',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Created on',
      value: '15',
    },
  ];

  const onChangeManufacturer = (e: ChangeEvent<HTMLInputElement>, v: boolean) => {
    if (v === true) {
      setManufacturerIds(() => [...manufacturerIds, +e.target.value]);
    } else {
      setManufacturerIds(manufacturerIds.filter(item => item !== +e.target.value));
    }
  };

  const onChangePage = (v: number) => {
    setPageNumber(v);
  };

  const onChangePriceRange = (e: Event, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  const handleFilterRequest = () => {
    refetchProducts();
  };

  useEffect(() => {
    updateBreadcrumbs([]);
    sendEventToGTM('category_view', {
      category_id: categoryId,
      category_se_name: seName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: categoryDetails,
    // isLoading: categoryLoading,
    isFetching: isCategoryDetailsFetching,
    error: isCategoryDetailsError,
  } = useQuery({
    queryKey: ['categoryDetails', categoryId],
    queryFn: getData(() => getCategoryById({ categoryId: +categoryId! })),
    enabled: !!categoryId,
  });

  // Side effect for getCategoryById query
  useEffect(() => {
    if (categoryDetails) {
      const categoryData = categoryDetails?.categoryModelDto;
      const rangeFrom = categoryData?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.from;
      const rangeTo = categoryData?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.to;

      // Update the price range if available
      if (rangeFrom && rangeTo) {
        setPriceRange([rangeFrom, rangeTo]);
      }

      // Set breadcrumbs
      const breadcrumbs: BreadcrumbItem[] = [
        { label: 'All Categories', link: '/all-categories' },
        ...(categoryData?.categoryBreadcrumb || []).map(item => ({
          label: item.name,
          link: `/category/${item.id}/${item.seName}`,
        })),
      ];
      updateBreadcrumbs(breadcrumbs);
    }
  }, [categoryDetails, updateBreadcrumbs]);

  const {
    data: categoryProductsResponse,
    isFetching: isCategoryProductsFetching,
    error: isCategoryProductsError,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ['categoryProducts', categoryId, pageNumber, pageSize, orderBy],
    queryFn: getData(() =>
      getCategoryProducts({
        categoryId: +categoryId!,
        body: {
          price: `${priceRange[0]} - ${priceRange[1]}`,
          pageSize,
          pageNumber,
          orderBy,
          manufacturerIds,
        },
      }),
    ),
    enabled: !!categoryId,
  });

  // Side effect for getCategoryProducts query
  useEffect(() => {
    const catalogData = categoryProductsResponse?.catalogProductsModel;
    if (catalogData?.pageNumber && catalogData.pageNumber !== pageNumber) {
      setPageNumber(catalogData.pageNumber);
    }
    setManufacturerOptions(catalogData?.manufacturerFilter?.manufacturers || []);

    // Pre-select manufacturers based on the response
    const selectedManufacturerIds = catalogData?.manufacturerFilter?.manufacturers
      ?.filter(manufacturer => manufacturer.selected)
      .map(manufacturer => +manufacturer.value!);
    setManufacturerIds(selectedManufacturerIds || []);

    const rangeFrom = catalogData?.priceRangeFilter?.availablePriceRange?.from;
    const rangeTo = catalogData?.priceRangeFilter?.availablePriceRange?.to;
    if (rangeFrom && rangeTo) setPriceRange([rangeFrom, rangeTo]);

    // Smooth scroll to the top
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 150);
  }, [categoryProductsResponse, pageNumber]);

  return {
    categoryId,
    categoryProductsResponse,
    categoryDetails,
    handleFilterRequest,
    isCategoryDetailsFetching,
    isCategoryProductsFetching,
    totalItems: categoryProductsResponse?.catalogProductsModel.totalItems,
    totalPages: categoryProductsResponse?.catalogProductsModel.totalPages,
    pageSize,
    pageSizeOptions,
    manufacturerIds,
    setPageSize,
    orderBy,
    availableSortOptions,
    setOrderBy,
    pageNumber,
    onChangePage,
    priceRange,
    onChangePriceRange,
    manufacturerOptions,
    onChangeManufacturer,
    isTouchDevice,
    isCategoryDetailsError,
    isCategoryProductsError,
  };
};
