import { ButtonSkeleton, InputSkeleton, TypographySkeleton } from 'design-system';
import styles from './styles.module.scss';
import { Box, Grid } from '@mui/material';
import classNames from 'classnames';

export const CallToActionSkeleton = () => {
  return (
    <>
      <Box className={styles.call__to__action__container}>
        <Grid container spacing={3} height={'100%'}>
          <Grid item xs={12} md={6.5} className="z-10">
            <Box className={classNames(styles.rfq__content, 'responsive-full-width-ignore-container-padding')}>
              <TypographySkeleton variant="h3" className="w-1/2" />
              <TypographySkeleton variant="subtitle2" className="w-1/3" />
            </Box>
          </Grid>

          <Grid item xs={12} md={5.5}>
            <Grid container spacing={3} className={styles.homepage__rfq__fields}>
              {Array(2)
                .fill(null)
                .map((_, idx) => (
                  <Grid item xs={12} md={12} key={`call-to-action-skeleton-item-${idx}`}>
                    <InputSkeleton />
                  </Grid>
                ))}

              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={8}>
                    <InputSkeleton />
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <InputSkeleton />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <InputSkeleton />
              </Grid>

              <Grid item xs={12} md={12}>
                <ButtonSkeleton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
