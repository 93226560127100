import { NoResult, Typography } from 'design-system';
import { ReactElement } from 'react';
import { Box } from '@mui/material';

type Props = {
  title: string;
  description: string;
  buttonComponent?: ReactElement;
};

export const HasNoResult = ({ title, description, buttonComponent }: Props) => {
  return (
    <>
      <div id="NoSearch Icon" className="max-w-[450px] max-sm:max-w-[300px] mx-auto -translate-y-24">
        <NoResult />
      </div>

      <Box className="-translate-y-64 max-sm:-translate-y-44">
        <Typography
          variant="h6"
          component="h6"
          className="mx-auto text-center text-darkGray mb-2 max-sm:w-[300px]"
          id="NoSearchTitle"
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          className="mx-auto text-center text-midLightGray mb-3 max-sm:mb-2 max-sm:w-[325px]"
          id="NoSearchText"
        >
          {description}
        </Typography>

        <Box className="flex justify-center">{buttonComponent}</Box>
      </Box>
    </>
  );
};
