import { Skeleton, SkeletonProps } from '@mui/material';

export const ButtonSkeleton = (props: SkeletonProps) => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      sx={{ height: '56px', backgroundColor: 'var(--gray)', borderRadius: '12px' }}
      {...props}
    />
  );
};
