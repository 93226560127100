import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { AppContainer, Button, Loader, ProductCard, Typography } from 'design-system';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useBrowseByCategory } from './useBrowseByCategory';
import { FreeMode, Navigation } from 'swiper/modules';
import styles from './styles.module.scss';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export const BrowseByCategory = () => {
  const {
    categoryProductsResponse,
    setCategorySeName,
    categorySeName,
    catalogRoot,
    setCategoryId,
    categoryId,
    sliderRef,
    loading,
  } = useBrowseByCategory();

  const categoriesSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: 12,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.browse-by-category-swiper.custom-swiper-button-prev',
      nextEl: '.browse-by-category-swiper.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  return (
    <Box className="flex flex-col gap-7">
      <Box className={styles.browse__by__category__header}>
        <AppContainer>
          <Typography variant="h3" component="h3" className="text-darkGray">
            Browse By Category
          </Typography>

          <div className={`swiper-with-arrows`}>
            <div
              className="browse-by-category-swiper custom-swiper-button-prev"
              onClick={() => sliderRef?.current?.slidePrev()}
            >
              <CaretRight />
            </div>

            <Swiper {...categoriesSwiperProps}>
              {catalogRoot?.map(category => (
                <SwiperSlide
                  style={{ width: 'auto', padding: '16px 0' }}
                  key={`best-selling-category-slide-${category?.id}`}
                >
                  {categoryId !== category.id && (
                    <Button
                      variant="outlined"
                      size="small"
                      color="inherit"
                      className="border-gray text-midLightGray rounded-lg max-md:h-8"
                      onClick={() => {
                        setCategoryId(category.id);
                        setCategorySeName(category.seName!);
                      }}
                      // startIcon={
                      //   category?.iconModel?.fullSizeImageUrl && (
                      //     <img
                      //       src={category?.iconModel?.fullSizeImageUrl || ''}
                      //       alt={category?.iconModel?.alternateText || ''}
                      //       className={styles.category__icon}
                      //     />
                      //   )
                      // }
                      label={category.name || ''}
                    />
                  )}

                  {categoryId === category.id && (
                    <Button
                      size="small"
                      onClick={() => {
                        setCategoryId(category.id);
                        setCategorySeName(category.seName!);
                      }}
                      // startIcon={
                      //   category?.iconModel?.fullSizeImageUrl && (
                      //     <img
                      //       src={category?.iconModel?.fullSizeImageUrl || ''}
                      //       alt={category?.iconModel?.alternateText || ''}
                      //       className={styles.category__icon}
                      //     />
                      //   )
                      // }
                      label={category.name || ''}
                      className="rounded-lg max-md:h-8"
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>

            <div
              className="browse-by-category-swiper custom-swiper-button-next z-[150]"
              onClick={() => sliderRef?.current?.slideNext()}
            >
              <CaretRight />
            </div>
          </div>
        </AppContainer>
      </Box>

      <AppContainer>
        {loading && <Loader />}

        <Grid container spacing={{ xs: 1.5, md: 3 }}>
          {categoryProductsResponse?.catalogProductsModel?.products?.slice(0, 20).map(product => (
            <Grid key={`nop-hit-${product.id}`} item xs={6} sm={4} md={2.4}>
              <Link to={`/product-details/${product.id}/${product.seName}`} state={{ fromSearch: false }}>
                <ProductCard
                  key={product.id}
                  pName={product.name}
                  image={product.pictureModels![0].fullSizeImageUrl}
                  price={product.productPrice.price}
                  unit={product.unit}
                />
              </Link>
            </Grid>
          ))}
        </Grid>

        {categoryProductsResponse && categoryProductsResponse.catalogProductsModel.totalItems > 20 && (
          <Box className="w-full flex justify-center pt-12 max-md:pt-5">
            <Link to={`/category/${categoryId}/${categorySeName}`} state={{ fromSearch: false }}>
              <Button label="View More" />
            </Link>
          </Box>
        )}
      </AppContainer>
    </Box>
  );
};
