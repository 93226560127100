import { Skeleton, TypographyProps } from '@mui/material';
import { Typography } from '../typography';

export const TypographySkeleton = (props: TypographyProps) => {
  return (
    <Typography {...props}>
      <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '8px', backgroundColor: 'var(--gray)' }} />
    </Typography>
  );
};
