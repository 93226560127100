import { ProductCardSkeleton } from 'design-system';
import { Grid } from '@mui/material';

export const NopHitsSkeleton = () => {
  return (
    <Grid container spacing={{ xs: 1.5, md: 3 }}>
      {Array(20)
        .fill(null)
        .map((_, idx) => (
          <Grid item xs={6} sm={4} md={3} key={`nop-hits-skeleton-item-${idx}`}>
            <ProductCardSkeleton />
          </Grid>
        ))}
    </Grid>
  );
};
