import { SelectListItemDto } from 'otl-codegen/dist/marketplace-frontend';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { getVendorProducts } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { getData, htmlToText, sendEventToGTM } from 'utils';
import { useGetVendorById } from 'hooks/useGetVendorById';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from '@mui/material';
import { useCustomAuth } from 'hooks';

export const useVendorDetails = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const { handleContactSeller, currentChannelUrl } = useMessageCenterContext();
  const { isAuthenticated, loginWithRedirect } = useCustomAuth();
  const { updateBreadcrumbs } = useBreadcrumbs();

  const [pageSize, setPageSize] = useState<number>(40);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<number>(5);
  const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);

  const [contactSellerClicked, setContactSellerClicked] = useState<boolean>(false);
  const [descriptionTextResp, setDescriptionTextResp] = useState<string | null | undefined>();
  const [fullDescriptionOpen, setFullDescriptionOpen] = useState<boolean>(false);

  const productsRef = useRef<HTMLDivElement>(null);

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const pageSizeOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: true,
      text: '40',
      value: '40',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '60',
      value: '60',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '100',
      value: '100',
    },
  ];

  const availableSortOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: A to Z',
      value: '5',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: Z to A',
      value: '6',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: Low to High',
      value: '10',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: High to Low',
      value: '11',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Created on',
      value: '15',
    },
  ];

  const { isVendorDetailsLoading, vendorDetails, isErrorVendorDetails } = useGetVendorById({
    vendorId: +vendorId!,
  });

  const {
    data: vendorProductsResponse,
    isLoading: vendorProductsLoading,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ['vendorProducts', vendorId, pageNumber, pageSize, orderBy],
    queryFn: getData(() =>
      getVendorProducts({
        vendorId: +vendorId!,
        body: {
          price: `${priceRange[0]} - ${priceRange[1]}`,
          pageSize,
          pageNumber,
          orderBy,
        },
      }),
    ),
    enabled: !!vendorId,
  });

  useEffect(() => {
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vendorDetails) {
      updateBreadcrumbs([
        {
          label: `${vendorDetails.name}`,
          link: `vendor-details/${vendorDetails?.id}`,
        },
      ]);
      sendEventToGTM('view_vendor_profile', {
        vendor_id: vendorDetails?.id,
        vendor_name: vendorDetails?.name,
      });
    }

    vendorDetails?.description &&
      setDescriptionTextResp(htmlToText(vendorDetails?.description)?.substring(0, 65).concat('...'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorDetails]);

  useEffect(() => {
    if (vendorDetails?.description)
      if (fullDescriptionOpen) {
        setDescriptionTextResp(htmlToText(vendorDetails?.description));
      } else setDescriptionTextResp(htmlToText(vendorDetails?.description)?.substring(0, 65).concat('...'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullDescriptionOpen]);

  useEffect(() => {
    if (isTouchDevice && currentChannelUrl !== '' && contactSellerClicked) {
      navigate(`/channels/${currentChannelUrl}`);
      setContactSellerClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl, contactSellerClicked]);

  // Side effect for getCategoryProducts query
  useEffect(() => {
    const catalogData = vendorProductsResponse?.catalogProductsModel;
    const pageNumberData = catalogData?.pageNumber;
    const orderByData = catalogData?.orderBy;
    const priceRangeData = catalogData?.priceRangeFilter.selectedPriceRange;

    if (pageNumberData && pageNumberData !== pageNumber) {
      setPageNumber(pageNumberData);
    }
    if (orderByData && orderByData !== orderBy) {
      setOrderBy(orderByData);
    }
    if (priceRangeData) {
      setPriceRange([priceRangeData.from!, priceRangeData.to!]);
    }

    // Smooth scroll to the top
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 150);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorProductsResponse]);

  const onContactSeller = () => {
    if (isAuthenticated && vendorDetails) {
      setContactSellerClicked(true);
      const message = 'Hi, I am interested in some products you listed and need more details.';
      handleContactSeller({
        message,
        vendorName: vendorDetails.name || '',
        vendorRepresentativeEmail: vendorDetails.authorizedEmails || '',
        vendorId: vendorDetails.id,
      });
      sendEventToGTM('vendor_details_page_negotiate', {
        vendor_id: vendorDetails.id,
        vendor_name: vendorDetails.name,
      });
    } else loginWithRedirect({ redirectUri: window.location.pathname });
  };

  const handleFilterRequest = () => {
    refetchProducts();
  };

  return {
    vendorDetails,
    onContactSeller,
    isTouchDevice,
    descriptionTextResp,
    fullDescriptionOpen,
    setFullDescriptionOpen,
    isVendorDetailsLoading,
    isErrorVendorDetails,

    vendorProductsResponse,
    totalItems: vendorProductsResponse?.catalogProductsModel.totalItems,
    totalPages: vendorProductsResponse?.catalogProductsModel.totalPages,

    vendorProductsLoading,
    handleFilterRequest,
    pageSizeOptions,
    availableSortOptions,
    setPageSize,
    setOrderBy,
    priceRange,
    setPriceRange: (event: Event, newValue: number | number[]) => {
      setPriceRange(newValue as number[]);
    },
    productsRef,
    vendorId,
    pageSize,
    orderBy,
    pageNumber,
    setPageNumber,
  };
};
