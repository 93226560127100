import {
  HorizontalArrowPrev,
  HorizontalArrowNext,
  VerticalArrowPrev,
  VerticalArrowNext,
  ImageMagnifier,
} from 'components';
import { Navigation, Thumbs, FreeMode, Controller, Pagination, Mousewheel } from 'swiper/modules';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { PictureModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import { MediaModalContent } from '../media-modal-content';
import { Box, Grid, useMediaQuery } from '@mui/material';
import type { Swiper as SwiperType } from 'swiper';
import { Modal, Typography } from 'design-system';
import styles from './styles.module.scss';
import './styles.scss';

type Props = {
  pictureModels?: PictureModelDto[] | null;
  defaultPictureModel: PictureModelDto;
  currentPictureIndex: number;
};

export const ProductMedia = ({ pictureModels, defaultPictureModel, currentPictureIndex }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const mainSwiperRef = useRef<any>(null);
  const verticalSwiperRef = useRef<any>(null);

  const mainSwiperProps: SwiperProps = {
    loop: true,
    navigation: {
      prevEl: '.horizontal__slider__prev',
      nextEl: '.horizontal__slider__next',
    },
    modules: [FreeMode, Navigation, Thumbs, Controller],
    onSwiper: swiper => {
      if (mainSwiperRef.current !== null) {
        mainSwiperRef.current = swiper;
      }
    },
    thumbs: {
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
    },
    className: 'gallery__swiper',
  };

  const verticalSwiperProps: SwiperProps = {
    onSwiper: setThumbsSwiper,
    direction: 'vertical',
    spaceBetween: 12,
    slidesPerView: 5.5,
    navigation: {
      prevEl: '.vertical__slider__prev',
      nextEl: '.vertical__slider__next',
    },
    slideToClickedSlide: true,
    modules: [Navigation, Thumbs, Controller, Mousewheel],
    mousewheel: true,
    className: 'thumbnail__swiper',
  };

  const responsiveSwiperProps: SwiperProps = {
    loop: true,
    modules: [FreeMode, Thumbs, Controller, Pagination],
    pagination: {
      el: '.fraction__component',
      type: 'custom',
    },
    onSlideChangeTransitionEnd: swiper => {
      setCurrentSlideIndex(swiper.realIndex);
    },
    className: 'responsive__swiper',
  };

  useEffect(() => {
    if (mainSwiperRef && mainSwiperRef.current) {
      mainSwiperRef.current.slideTo(currentPictureIndex);
    }
  }, [currentPictureIndex]);

  useLayoutEffect(() => {
    if (mainSwiperRef.current !== null) {
      mainSwiperRef.current.controller.control = verticalSwiperRef.current;
    }
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const renderSlides = (
    slides: PictureModelDto[] | null | undefined,
    renderSlideContent: (slide: PictureModelDto) => JSX.Element,
  ) => {
    if (!slides || slides.length === 0) {
      slides = [defaultPictureModel as PictureModelDto];
    }
    return slides.map((slide, index) => <SwiperSlide key={index}>{renderSlideContent(slide)}</SwiperSlide>);
  };

  const renderVerticalSlides = () => {
    const renderVerticalSlideContent = (slide: PictureModelDto) => (
      <div className="vertical__slider__image">
        <img src={slide.fullSizeImageUrl || ''} alt={slide.alternateText || ''} />
      </div>
    );
    return renderSlides(pictureModels as PictureModelDto[], renderVerticalSlideContent);
  };

  const renderMainSlides = () => {
    const renderMainSlideContent = (slide: PictureModelDto) => <ImageMagnifier imageUrl={slide.fullSizeImageUrl} />;
    return renderSlides(pictureModels as PictureModelDto[], renderMainSlideContent);
  };

  return (
    <div className={styles.product__media__container}>
      <Modal
        open={isModalOpen}
        content={
          <MediaModalContent
            pictureModels={pictureModels}
            defaultPictureModel={defaultPictureModel}
            handleClose={handleClose}
            currentSlideIndex={currentSlideIndex}
          />
        }
        header={
          <Box className="ml-auto w-fit bg-lightGray rounded-full p-2 mb-2" onClick={() => handleClose()}>
            <CloseIcon fill="black" />
          </Box>
        }
        handleClose={handleClose}
        modalProps={{ style: { height: '100vh', width: '100vw', justifyContent: 'center' } }}
      />

      {!isTouchDevice && (
        <Grid container>
          <Grid item xs={2}>
            <div className="thumbs__slider">
              <div className="vertical__slider__prev">
                <VerticalArrowPrev stroke="var(--white)" className="bg-deepYellow w-6 h-6" />
              </div>
              <Swiper {...verticalSwiperProps} ref={verticalSwiperRef}>
                {renderVerticalSlides()}
              </Swiper>
              <div className="vertical__slider__next">
                <VerticalArrowNext stroke="var(--white)" className="bg-deepYellow w-6 h-6" />
              </div>
            </div>
          </Grid>

          <Grid item xs={10}>
            <div className="main__slider">
              <div className="horizontal__slider__prev">
                <HorizontalArrowPrev stroke="var(--deepYellow)" className="border border-lightGray" />
              </div>
              <Swiper {...mainSwiperProps} ref={mainSwiperRef}>
                {renderMainSlides()}
              </Swiper>
              <div className="horizontal__slider__next">
                <HorizontalArrowNext stroke="var(--deepYellow)" className="border border-lightGray" />
              </div>
            </div>
            <Typography variant="body2" className="text-midLightGray mt-2">
              Hover to zoom in
            </Typography>
          </Grid>
        </Grid>
      )}

      {isTouchDevice && (
        <Swiper {...responsiveSwiperProps}>
          {pictureModels && pictureModels.length > 0 && (
            <div className={styles.fraction__container}>
              <Typography variant="caption" className="text-white">
                {currentSlideIndex + 1} / {pictureModels.length}
              </Typography>
            </div>
          )}
          {pictureModels &&
            pictureModels.length > 0 &&
            pictureModels.map((slide, index) => {
              return (
                <SwiperSlide key={index} onClick={() => setIsModalOpen(true)}>
                  <img src={slide.fullSizeImageUrl || ''} alt={slide.alternateText || ''} />
                </SwiperSlide>
              );
            })}

          {!(pictureModels && pictureModels.length > 0) && (
            <SwiperSlide key="default-image" onClick={() => setIsModalOpen(true)}>
              <img src={defaultPictureModel.fullSizeImageUrl || ''} alt={defaultPictureModel.alternateText || ''} />
            </SwiperSlide>
          )}
        </Swiper>
      )}
    </div>
  );
};
