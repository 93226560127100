import { ProductDetailsModelDto, VendorModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { VendorCardActions } from '../vendor-card-actions';
import styles from './styles.module.scss';
import { HTMLAttributes } from 'react';
import { Card } from '@mui/material';

type Props = {
  vendorDetails: VendorModelDto;
  productDetailsModel: ProductDetailsModelDto;
} & HTMLAttributes<HTMLDivElement>;

export const ProductVendorCardResponsive = ({ vendorDetails, productDetailsModel, ...props }: Props) => {
  return (
    <div
      {...props}
      className="z-[999] sticky left-0 bottom-14 w-screen mt-6 responsive-full-width-ignore-container-padding-with-margin"
    >
      <Card key={`Vendor ${vendorDetails?.id}`} className={styles.vendor__card__responsive}>
        <div className={styles.vendor__card__responsive__container}>
          <img
            src={vendorDetails.pictureModel.fullSizeImageUrl || ''}
            alt={vendorDetails.pictureModel.alternateText || ''}
          />

          <VendorCardActions
            vendorDetails={vendorDetails}
            productDetailsModel={productDetailsModel}
            secondaryButtonText="View Profile"
          />
        </div>
      </Card>
    </div>
  );
};
