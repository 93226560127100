import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import {
  ProductDetailsAttributeChangeResponse,
  ProductDetailsModelDto,
  VendorModelDto,
} from 'otl-codegen/dist/marketplace-frontend';
import { ReactComponent as ShareIcon } from 'design-system/public/icons/share.svg';
import { Box, Popover, Link, Divider, Grid } from '@mui/material';
import { RequestSampleDrawer } from '../reuqest-sample-drawer';
import { VendorCardActions } from '../vendor-card-actions';
import { IconButton, Typography } from 'design-system';
import { ColorsBar } from '../attributes/colors-bar';
import { useMainDetails } from './useMainDetails';
import { SizeBar } from '../attributes/size-bar';
import { TextBar } from '../attributes/text-bar';
import { TierPrices } from '../tiers-prices';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { getUnit } from 'utils';

type MainDetailsProps = {
  productDetailsModel: ProductDetailsModelDto;
  vendorDetails: VendorModelDto;
  onProductAttributeChange: (productAttributeChangeResponse: ProductDetailsAttributeChangeResponse) => void;
  currentPictureIndex?: number;
};

const Copy = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
      <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
    </svg>
  );
};

const Check = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
};

const SharePopover = ({ id, open, anchorEl, handleClose, url, copied, setCopied }) => {
  const handleCopyClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(url);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={10}
      disableScrollLock
    >
      <Box className="flex flex-col p-5">
        <Box className="flex">
          <Box className="flex flex-col items-center me-4 py-5">
            <FacebookShareButton url={url} className="mb-3">
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <Typography variant="caption">Facebook</Typography>
          </Box>

          <Box className="flex flex-col items-center me-4 py-5">
            <WhatsappShareButton url={url} className="mb-3">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <Typography variant="caption">WhatsApp</Typography>
          </Box>

          <Box className="flex flex-col items-center me-4 py-5">
            <LinkedinShareButton url={url} className="mb-3">
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <Typography variant="caption">Linkedin</Typography>
          </Box>

          <Box className="flex flex-col items-center me-4 py-5">
            <TwitterShareButton url={url} className="mb-3">
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <Typography variant="caption">Twitter</Typography>
          </Box>
        </Box>

        <button className={styles.copy__link} onClick={handleCopyClick}>
          <Copy
            className={styles.copy__icon}
            style={{
              strokeDashoffset: copied ? -50 : 0,
            }}
          />
          <Check
            className={styles.check__icon}
            style={{
              strokeDashoffset: copied ? 0 : -50,
            }}
          />
          <Typography
            variant="body2"
            style={{
              opacity: !copied ? 1 : 0,
              transition: 'opacity 500ms ease-in-out',
              position: 'absolute',
              top: '50%',
              transform: 'translate(10%, -50%)',
              marginInlineStart: '16px',
            }}
          >
            Copy Link
          </Typography>
          <Typography
            variant="body2"
            style={{
              opacity: copied ? 1 : 0,
              transition: 'opacity 500ms ease-in-out',
              position: 'absolute',
              top: '50%',
              transform: 'translate(10%, -50%)',
              marginInlineStart: '16px',
            }}
          >
            Link Copied
          </Typography>
        </button>
      </Box>
    </Popover>
  );
};

export const MainDetails = ({
  productDetailsModel,
  vendorDetails,
  onProductAttributeChange,
  currentPictureIndex,
}: MainDetailsProps) => {
  const {
    anchorEl,
    sharePopoverOpen,
    handleShareClick,
    handleClose,
    id,
    url,
    navigate,
    colorsAttribute,
    sizeAttribute,
    thicknessAttribute,
    finishAttribute,
    contentRef,
    productAttributeChange,
    callAttributeChange,
    copied,
    setCopied,
    contentScrolled,
    isTouchDevice,

    productName,
    shortDescription,
    sku,
    tierPrices,
    moq,

    requestSampleFlagEnabled,

    getSampleDrawerOpen,
    toggleGetSampleDrawer,

    activeColorAttrValue,
    activeSizeAttrValue,
    activeFinishAttrValue,
    activeThicknessAttrValue,
  } = useMainDetails({ productDetailsModel, onProductAttributeChange });

  return (
    <Box className={styles.main__details__container}>
      {!isTouchDevice && (
        <Box className={classNames(styles.title__container, contentScrolled ? styles.title__container__scrolled : '')}>
          <Typography variant="h5" component="h5" className="text-darkGray me-4 max-w-[80%]" id="ProductName">
            {productName}
          </Typography>

          <IconButton
            icon={<ShareIcon />}
            size="small"
            className="p-0"
            onClick={handleShareClick}
            aria-describedby={id}
          />

          <SharePopover
            id={id}
            anchorEl={anchorEl}
            handleClose={handleClose}
            open={sharePopoverOpen}
            url={url}
            copied={copied}
            setCopied={setCopied}
          />
        </Box>
      )}

      <Box ref={contentRef} className={styles.content__container}>
        {!isTouchDevice && shortDescription && (
          <Typography variant="body2" className="text-midDarkGray mb-3" id="ShortDescription">
            {shortDescription}
          </Typography>
        )}

        {sku && (
          <Typography variant="body2" className="text-violet mb-3" id="SKU">
            SKU: {productAttributeChange?.sku ? productAttributeChange?.sku : productDetailsModel?.sku}
          </Typography>
        )}

        {tierPrices?.length! > 0 && (
          <TierPrices tierPrices={tierPrices} unit={productDetailsModel.unit as string} className="mb-4" />
        )}

        <Box className="mb-4 flex items-center">
          <Typography variant="h6" className="text-magentaPink" id="Price">
            Price:{' '}
            {productAttributeChange?.price ? productAttributeChange?.price : productDetailsModel?.productPrice.price}{' '}
            <Typography variant="body2" className="text-midLightGray" id="Unit" component="span">
              {getUnit(productDetailsModel)}
            </Typography>
          </Typography>
        </Box>

        <Box className="mb-4 flex items-center">
          {requestSampleFlagEnabled && (
            <Typography variant="subtitle2" component="span">
              Still deciding?
              <Typography
                className="text-deepYellow underline ms-2 cursor-pointer"
                component="span"
                onClick={() => toggleGetSampleDrawer(true)}
              >
                Request a sample
              </Typography>
            </Typography>
          )}

          <RequestSampleDrawer
            getSampleDrawerOpen={getSampleDrawerOpen}
            toggleGetSampleDrawer={toggleGetSampleDrawer}
            selectedCurrentPictureIndex={currentPictureIndex}
            selectedActiveColorAttrValue={activeColorAttrValue}
            selectedActiveSizeAttrValue={activeSizeAttrValue}
            selectedActiveThicknessAttrValue={activeThicknessAttrValue}
            selectedActiveFinishAttrValue={activeFinishAttrValue}
          />
        </Box>

        {moq && (
          <Box className={styles.moq__container}>
            <Typography variant="subtitle2" className="text-darkGray">
              Minimum Order Quantity :{' '}
              <Typography variant="body2" component="span" className="text-midDarkGray">
                {moq}
              </Typography>
            </Typography>
          </Box>
        )}

        {colorsAttribute && (
          <ColorsBar
            className="mb-6"
            attr={colorsAttribute}
            onAttributeChange={callAttributeChange}
            activeValue={activeColorAttrValue}
          />
        )}

        {sizeAttribute && (
          <SizeBar
            className="mb-6"
            attr={sizeAttribute}
            onAttributeChange={callAttributeChange}
            activeValue={activeSizeAttrValue}
          />
        )}

        {thicknessAttribute && (
          <TextBar
            className="mb-6"
            attr={thicknessAttribute}
            onAttributeChange={callAttributeChange}
            activeValue={activeThicknessAttrValue}
          />
        )}

        {finishAttribute && (
          <TextBar
            className="mb-6"
            attr={finishAttribute}
            onAttributeChange={callAttributeChange}
            activeValue={activeFinishAttrValue}
          />
        )}
      </Box>

      {!isTouchDevice && vendorDetails && vendorDetails?.name && (
        <>
          <Divider sx={{ borderColor: 'var(--lightGray)', width: '100%' }} />

          <Grid container paddingBlock={'16px'} spacing={1}>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid container spacing={'8px'} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <Box onClick={() => navigate(`/vendor-details/${vendorDetails.id}`)}>
                    <Box className={styles.vendor__icon__container}>
                      <img
                        src={vendorDetails.pictureModel.fullSizeImageUrl || ''}
                        alt={vendorDetails.pictureModel.alternateText || ''}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box onClick={() => navigate(`/vendor-details/${vendorDetails.id}`)}>
                    <Link href="#" underline="hover" className={styles.vendor__link}>
                      <Typography variant="subtitle1" className="text-magentaPink line-clamp-1">
                        {vendorDetails?.name}
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <VendorCardActions
                vendorDetails={vendorDetails}
                productDetailsModel={productDetailsModel}
                secondaryButtonText="View supplier profile"
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
