import { AccordionItemExpandedSkeleton, AccordionItemSkeleton, Typography } from 'design-system';
import { Skeleton } from '@mui/material';

export const FaqsSkeleton = () => {
  return (
    <>
      <Typography variant="h3" component="h3" className="w-1/3 mb-4 max-sm:mb-3">
        <Skeleton variant="text" animation="wave" sx={{ backgroundColor: 'var(--gray)' }} />
      </Typography>

      <AccordionItemExpandedSkeleton />
      <AccordionItemSkeleton />
      <AccordionItemSkeleton />
    </>
  );
};
