import { SortPropertyEnum, SortOptionEnum } from 'services/types/generic/Query.dto';
import { Sample } from 'services/types/generic/sample-entity';
import { getMySamples } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { GetAllResponse } from 'services/types/generic';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthToken } from 'hooks';
import { getData } from 'utils';
import moment from 'moment';

export const useSampleList = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { token } = useAuthToken();
  const navigate = useNavigate();

  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<'Newest' | 'Oldest'>('Newest');
  const [searchQuery, setSearchQuery] = useState('');

  const [showTableData, setShowTableData] = useState<boolean>(false);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [showHasNoTableData, setShowHasNoTableData] = useState<boolean>(false);
  const [showHasNoSearchResult, setShowHasNoSearchResult] = useState<boolean>(false);
  const [tableContainerHeight, setTableContainerHeight] = useState<string>('');

  const headerColumns = ['Product', 'Status', 'Creation Date', 'Vendor Name', 'Actions'];
  const selectColumns = ['productImage', 'status', 'createdAt', 'vendorName'];

  const take = 10;
  const takeResponsive = 5;

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const { data: mySamples, isFetching: isSamplesFetching } = useQuery({
    queryKey: ['mySamples', token, skip, orderBy, searchQuery],
    queryFn: getData(() =>
      getMySamples({
        body: {
          pagination: {
            skip,
            take: isTouchDevice ? takeResponsive : take,
          },
          sortProperty: SortPropertyEnum.CreatedAt,
          sortValue: orderBy === 'Newest' ? SortOptionEnum.desc : SortOptionEnum.asc,
          productName: searchQuery,
          description: searchQuery,
        },
        token: token!,
      }),
    ),
    enabled: !!token,
    select: (res: GetAllResponse<Sample>) => {
      const modifiedData = res.data.map((item: Sample) => ({
        ...item,
        createdAt: moment(item.CreatedAt).utc().format('MMMM DD, YYYY'),
        productImage: JSON.parse(item.variations).selectedImage,
        vendorName: JSON.parse(item.productDetails).vendorModel.name,
      }));
      return {
        ...res,
        data: modifiedData,
      };
    },
  });

  useEffect(() => {
    if (mySamples && !isTouchDevice) {
      setCount(Math.ceil((mySamples.count ?? 0) / take));
      setPageNumber(skip / take + 1);
    }
    if (mySamples && isTouchDevice) {
      setCount(Math.ceil((mySamples.count ?? 0) / takeResponsive));
      setPageNumber(skip / takeResponsive + 1);
    }
    const count = mySamples?.count;
    if (!isSamplesFetching && mySamples && count && count > 0) setShowTableData(true);
    else setShowTableData(false);

    if (!isSamplesFetching && mySamples && count && count > (isTouchDevice ? takeResponsive : take))
      setShowPagination(true);
    else setShowPagination(false);

    if (!isSamplesFetching && mySamples && count === 0 && searchQuery !== '') setShowHasNoSearchResult(true);
    else setShowHasNoSearchResult(false);

    if (!isSamplesFetching && mySamples && count === 0 && searchQuery === '') setShowHasNoTableData(true);
    else setShowHasNoTableData(false);
  }, [isTouchDevice, isSamplesFetching, mySamples, searchQuery, skip]);

  useEffect(() => {
    if (showPagination) setTableContainerHeight('calc(100vh - 145px - 24px - 22px - 115px)');
    else if (!showPagination) setTableContainerHeight('calc(100vh - 145px - 24px - 22px - 115px)');
  }, [showPagination, showHasNoSearchResult, showHasNoTableData]);

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Manage Samples List', link: '/request-sample' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSkip(0);
  }, [searchQuery, orderBy]);

  const onChangePage = (val: number) => {
    isTouchDevice ? setSkip((val - 1) * takeResponsive) : setSkip((val - 1) * take);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 150);
  };

  const handleViewDetails = (id: string | number) => {
    navigate(`/request-sample/details/${id}`);
  };

  return {
    // Data Related
    mySamples,
    isSamplesFetching,

    // UI Related
    headerColumns,
    selectColumns,
    showTableData,
    showPagination,
    showHasNoTableData,
    showHasNoSearchResult,
    tableContainerHeight,
    isTouchDevice,
    handleViewDetails,

    // Filter & Pagination Related
    count,
    pageNumber,
    onChangePage,
    setSearchQuery,
    orderBy,
    setOrderBy,
  };
};
