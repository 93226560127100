import { Skeleton } from '@mui/material';
import { Typography } from 'src/index';

export const InputSkeleton = () => {
  return (
    <>
      <Typography variant="subtitle2" className="w-1/6 mb-2">
        <Skeleton variant="rectangular" animation="wave" sx={{ backgroundColor: 'var(--gray)', borderRadius: '4px' }} />
      </Typography>
      <Skeleton variant="rectangular" animation="wave" sx={{ height: '56px', borderRadius: '4px' }} />
    </>
  );
};
