import { BestSellingProductsSkeleton } from './components/best-selling-products-skeleton';
import { CategoryBestSellerSkeleton } from './components/category-best-sellers-skeleton';
import { PromotionsBannersSkeleton } from './components/promotions-banners-skeleton';
import { BrowseByCategorySkeleton } from './components/browse-by-category-skeleton';
import { CallToActionSkeleton } from './components/call-to-action-skeleton';
import { BestSellingProducts } from './components/best-selling-products';
import { CategoryBestSeller } from './components/category-best-sellers';
import { TopVendorsSkeleton } from './components/top-vendors-skeleton';
import { PromotionsBanners } from './components/promotions-banners';
import { BrowseByCategory } from './components/browse-by-category';
import { CallToAction } from './components/call-to-action';
import { FaqsSkeleton } from './components/faqs-skeleton';
import { TopVendors } from './components/top-vendors';
import { AppContainer } from 'design-system';
import { useHomePage } from './useHomePage';
import styles from './styles.module.scss';
import { Faqs } from './components/faqs';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import classNames from 'classnames';

const HomeHelmet = () => (
  <Helmet>
    <title>otrovato, B2B marketplace in Africa</title>
    <meta
      name="description"
      content="otrovato is designed to help buyers and sellers connect while simplifying the process of trading to make it accessible, efficient and secure all over Africa."
    />
    <meta
      name="keywords"
      content="B2B marketplace, Trade in Africa, Trade in Kenya, African Manufacturers and Suppliers, African Exporters and African Importers, African Trading Platform, Import products from Africa, Top B2B portals, best African Suppliers, best B2B suppliers, b2b trade portal, top 10 b2b seller, food b2b marketplace, best b2b portals, furniture b2b marketplace, construction b2b marketplace, b2b food suppliers, b2b exporters, chemicals b2b marketplace"
    />
  </Helmet>
);

const Section = ({ children, containerClass, hasAppContainer }) => (
  <Box className={containerClass}>
    {hasAppContainer && <AppContainer>{children}</AppContainer>}
    {!hasAppContainer && <>{children}</>}
  </Box>
);

const renderComponentOrSkeleton = (isLoading: boolean, component: any, skeleton: any) => {
  return isLoading ? skeleton : component;
};

export const Home = () => {
  const { promotions, bestSellingProducts, homePageCategories, topVendors, faqs, isLoading } = useHomePage();

  return (
    <>
      <HomeHelmet />

      <Section containerClass={classNames(styles.promotion__section__container, styles.section__odd)} hasAppContainer>
        {renderComponentOrSkeleton(
          isLoading,
          <PromotionsBanners promotions={promotions} />,
          <PromotionsBannersSkeleton />,
        )}
        {renderComponentOrSkeleton(
          isLoading,
          <BestSellingProducts products={bestSellingProducts} />,
          <BestSellingProductsSkeleton />,
        )}
      </Section>

      <Section containerClass={classNames(styles.promotion__section__container, styles.section__odd)} hasAppContainer>
        {renderComponentOrSkeleton(
          isLoading,
          <CategoryBestSeller categories={homePageCategories} />,
          <CategoryBestSellerSkeleton />,
        )}
      </Section>

      <Section containerClass={classNames(styles.section__container, styles.section__odd)} hasAppContainer>
        {renderComponentOrSkeleton(isLoading, <TopVendors topVendors={topVendors} />, <TopVendorsSkeleton />)}
      </Section>

      <Section containerClass={classNames(styles.section__even)} hasAppContainer={false}>
        {renderComponentOrSkeleton(isLoading, <CallToAction />, <CallToActionSkeleton />)}
      </Section>

      <Section
        containerClass={classNames(
          styles.section__container,
          styles.section__odd,
          styles.categories__section__container,
        )}
        hasAppContainer={false}
      >
        {renderComponentOrSkeleton(isLoading, <BrowseByCategory />, <BrowseByCategorySkeleton />)}
      </Section>

      {faqs && faqs.results && faqs?.results.length > 0 && (
        <Section containerClass={classNames(styles.section__container, styles.section__faq)} hasAppContainer>
          {renderComponentOrSkeleton(isLoading, <Faqs faqs={faqs} />, <FaqsSkeleton />)}
        </Section>
      )}
    </>
  );
};
