import { PictureModelDto, ProductDetailsAttributeChangeResponse } from 'otl-codegen/dist/marketplace-frontend';
import { BreadcrumbItem, useBreadcrumbs } from 'contexts/breadcrumbContext';
import { getProductById } from 'services/orchestrator.service';
import { useGetVendorById } from 'hooks/useGetVendorById';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getData, sendEventToGTM } from 'utils';
import { useMediaQuery } from '@mui/material';

export const useProductDetails = () => {
  const { productId, seName } = useParams();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(0);
  const [isUpperPartInView, setIsUpperPartInView] = useState<boolean>(true);
  const [productAttributeChange, setProductAttributeChange] = useState<ProductDetailsAttributeChangeResponse>();
  const [currentPictureIndex, setCurrentPictureIndex] = useState<number>(0);

  const upperPartRef = useRef<any>(null);
  const nodeRef = useRef(null);

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const {
    data: productDetails,
    isLoading: isProductDetailsLoading,
    isError: isErrorProductDetails,
  } = useQuery({
    queryKey: ['productDetails', productId],
    queryFn: getData(() => getProductById(+productId!)),
    enabled: !!productId,
  });

  const { isVendorDetailsLoading, vendorDetails, isErrorVendorDetails } = useGetVendorById({
    vendorId: productDetails?.productDetailsModel.vendorModel?.id,
  });

  useEffect(() => {
    sendEventToGTM('view_product', {
      product_short_id: productId,
      product_se_name: seName,
    });
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Side effect for getProductById query
  useEffect(() => {
    if (productDetails) {
      const breadcrumbs: BreadcrumbItem[] = [
        ...(productDetails.productDetailsModel.breadcrumb.categoryBreadcrumb || []).map(item => {
          return {
            label: item.name,
            link: `/category/${item.id}/${item.seName}`,
          };
        }),
        {
          label: productDetails.productDetailsModel.name,
          link: `/product-details/${productDetails.productDetailsModel.id}/${productDetails.productDetailsModel.seName}`,
        },
      ];
      updateBreadcrumbs(breadcrumbs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  useEffect(() => {
    checkInView();
    document.addEventListener('scroll', checkInView);
    return () => {
      document.removeEventListener('scroll', checkInView);
    };
  }, []);

  useEffect(() => {
    const pictureModels = productDetails?.productDetailsModel.pictureModels;
    if (productAttributeChange && pictureModels) {
      const index = findPictureIndex(pictureModels, productAttributeChange);
      setCurrentPictureIndex(index);
    }
  }, [productDetails?.productDetailsModel.pictureModels, productAttributeChange]);

  const checkInView = () => {
    if (upperPartRef && upperPartRef.current) {
      const rect = upperPartRef.current.getBoundingClientRect();
      setIsUpperPartInView(rect.top < window.innerHeight && rect.bottom >= 150);
    }
  };

  const handleShareClick = () => {
    if (isTouchDevice && navigator.share && productDetails) {
      navigator.share({
        title: `${productDetails.productDetailsModel?.seName}`,
        text: `Check out ${productDetails.productDetailsModel?.seName} on `,
        url: document.location.href,
      });
    }
  };

  const findPictureIndex = (
    pictures: PictureModelDto[],
    productAttrChange: ProductDetailsAttributeChangeResponse,
  ): number => {
    if (productAttrChange.pictureFullSizeUrl) {
      return pictures.findIndex(picture => picture.fullSizeImageUrl === productAttrChange.pictureFullSizeUrl);
    } else return 0;
  };

  return {
    productId,
    currentTab,
    setCurrentTab,
    productDetails,
    vendorDetails,
    isUpperPartInView,
    isProductDetailsLoading,
    isVendorDetailsLoading,
    navigate,
    handleShareClick,
    productAttributeChange,
    setProductAttributeChange,
    upperPartRef,
    nodeRef,
    isErrorProductDetails,
    isErrorVendorDetails,
    isTouchDevice,
    currentPictureIndex,
    setCurrentPictureIndex,
  };
};
