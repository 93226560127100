import { TypographySkeleton } from 'design-system';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

export const LargeCategoryCardSkeleton = () => {
  return (
    <Box className={styles.category__card}>
      <Box className={styles.category__media__container} />
      <TypographySkeleton className="w-[70%] mb-3" />
      <TypographySkeleton className="w-[85%] mb-3" />
    </Box>
  );
};
