import { SortOptionEnum, SortPropertyEnum } from 'services/types/generic/Query.dto';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { getMyRfq } from 'services/orchestrator.service';
import { RFQ } from 'services/types/generic/rfq-entity';
import { GetAllResponse } from 'services/types/generic';
import { useAuthToken, useCancelRfq } from 'hooks';
import { useQuery } from '@tanstack/react-query';
import { getData, sendEventToGTM } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';

export type mappedRfq = {
  id: string;
  productName: string;
  createdAt: string;
  expDate: string;
  status: string;
  hint: string;
};

export const useRfqList = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { token } = useAuthToken();

  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<'Newest' | 'Oldest'>('Newest');
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedItem, setSelectedItem] = useState<RFQ>();

  const [showTableData, setShowTableData] = useState<boolean>(false);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [showHasNoTableData, setShowHasNoTableData] = useState<boolean>(false);
  const [showHasNoSearchResult, setShowHasNoSearchResult] = useState<boolean>(false);

  const [tableContainerHeight, setTableContainerHeight] = useState<string>('');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const take = 10;
  const takeResponsive = 5;

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const headerColumns = ['Product', 'Status', 'Creation Date', 'Expiry Date', 'Actions'];
  const selectColumns = ['productName', 'status', 'createdAt', 'expDate'];

  useEffect(() => {
    setSkip(0);
  }, [searchQuery, orderBy]);

  useEffect(() => {
    if (showPagination) setTableContainerHeight('calc(100vh - 145px - 24px - 22px - 115px)');
    else if (!showPagination) setTableContainerHeight('calc(100vh - 145px - 24px - 22px - 115px)');
  }, [showPagination, showHasNoSearchResult, showHasNoTableData]);

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ List', link: '/rfq' }]);
    sendEventToGTM('manage_rfq');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewDetails = (id: string | number) => {
    navigate(`/rfq/details/${id}`);
  };

  const {
    data: myRfq,
    isFetching: isRfqListFetching,
    refetch: refetchRfqList,
  } = useQuery({
    queryKey: ['myRfq', token, skip, orderBy, searchQuery],
    queryFn: getData(() =>
      getMyRfq({
        body: {
          pagination: {
            skip,
            take: isTouchDevice ? takeResponsive : take,
          },
          sortProperty: SortPropertyEnum.CreatedAt,
          sortValue: orderBy === 'Newest' ? SortOptionEnum.desc : SortOptionEnum.asc,
          productName: searchQuery,
          description: searchQuery,
        },
        token: token!,
      }),
    ),
    enabled: !!token,
    select: (res: GetAllResponse<RFQ>) => {
      const modifiedData = res.data.map((item: RFQ) => ({
        ...item,
        createdAt: moment(item.CreatedAt).utc().format('MMMM DD, YYYY'),
        expDate: moment(item.expDate).utc().format('MMMM DD, YYYY'),
      }));
      return {
        ...res,
        data: modifiedData,
      };
    },
  });

  useEffect(() => {
    if (myRfq && !isTouchDevice) {
      setCount(Math.ceil((myRfq.count ?? 0) / take));
      setPageNumber(skip / take + 1);
    }
    if (myRfq && isTouchDevice) {
      setCount(Math.ceil((myRfq.count ?? 0) / takeResponsive));
      setPageNumber(skip / takeResponsive + 1);
    }
    const count = myRfq?.count;
    if (!isRfqListFetching && myRfq && count && count > 0) setShowTableData(true);
    else setShowTableData(false);

    if (!isRfqListFetching && myRfq && count && count > (isTouchDevice ? takeResponsive : take))
      setShowPagination(true);
    else setShowPagination(false);

    if (!isRfqListFetching && myRfq && count === 0 && searchQuery !== '') setShowHasNoSearchResult(true);
    else setShowHasNoSearchResult(false);

    if (!isRfqListFetching && myRfq && count === 0 && searchQuery === '') setShowHasNoTableData(true);
    else setShowHasNoTableData(false);
  }, [isTouchDevice, isRfqListFetching, myRfq, searchQuery, skip]);

  const onChangePage = (val: number) => {
    isTouchDevice ? setSkip((val - 1) * takeResponsive) : setSkip((val - 1) * take);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 150);
  };

  const { cancelRfqMutation, isCancelRfqPending } = useCancelRfq({
    id: selectedItem?.id,
    token,
    refetch: refetchRfqList,
    setCancelModalOpen,
  });

  const handleCancelRfq = () => {
    if (token && selectedItem && selectedItem.id) {
      cancelRfqMutation();
    }
  };

  return {
    myRfq,
    orderBy,
    setOrderBy,
    setSearchQuery,
    onChangePage,
    handleViewDetails,
    setSelectedItem,
    isRfqListFetching,
    isCancelRfqPending,
    cancelModalOpen,
    setCancelModalOpen,
    handleCancelRfq,
    headerColumns,
    selectColumns,
    showTableData,
    showPagination,
    showHasNoSearchResult,
    showHasNoTableData,
    tableContainerHeight,
    count,
    pageNumber,
    isTouchDevice,
  };
};
