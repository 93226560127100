import { Skeleton, Box, Typography } from '@mui/material';
import styles from './styles.module.css';

export const ProductCardSkeleton = () => {
  return (
    <Box className={styles.product__card}>
      <Box className={styles.product__media__container} />

      <Typography variant="h6">
        <Skeleton variant="text" animation="wave" className="w-[70%] h-9 mb-3" sx={{ bgcolor: 'var(--gray)' }} />
      </Typography>
      <Typography variant="h6">
        <Skeleton variant="text" animation="wave" className="w-[85%] h-9" sx={{ bgcolor: 'var(--gray)' }} />
      </Typography>
    </Box>
  );
};
