import { QuotationsResponse } from 'services/dtos/quotation/quotations.response';
import { RFQFile } from 'services/types/rfq/RfqFile.type';
import { RFQ } from 'services/types/generic/rfq-entity';
import { countNewQuotationStatus } from 'utils';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

type Props = {
  setCancelModalOpen: Function;
  quotations: QuotationsResponse;
  quotationRef?: any;
  rfqFiles?: RFQFile[];
  rfqDetails: RFQ;
};

export const useRfqDetails = ({ setCancelModalOpen, quotations, quotationRef, rfqFiles, rfqDetails }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoverStates, setHoverStates] = useState<boolean[]>(Array(rfqFiles && rfqFiles.length).fill(false));
  const [isMainHovered, setIsMainHovered] = useState<boolean>(false);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');
  const open = Boolean(anchorEl);

  const handleMouseEnter = (index: number) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = true;
    setHoverStates(newHoverStates);
  };

  const handleMouseLeave = (index: number) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = false;
    setHoverStates(newHoverStates);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = () => {
    setCancelModalOpen(true);
    handleCloseMenu();
  };

  const scrollToTarget = () => {
    const targetPosition = quotationRef?.current.getBoundingClientRect().top + window.scrollY;
    const offset = 140; // Adjust the offset as needed

    window.scrollTo({
      top: targetPosition - offset,
      behavior: 'smooth',
    });
  };

  const menuItems = [{ text: 'Close', onClick: handleClickMenuItem }];

  return {
    isTouchDevice,
    handleMenuClick,
    menuItems,
    open,
    anchorEl,
    handleCloseMenu,
    newStatusCount: countNewQuotationStatus({ rfqDetails, quotations }),
    scrollToTarget,
    handleMouseEnter,
    handleMouseLeave,
    hoverStates,
    isMainHovered,
    setIsMainHovered,
  };
};
