import { Box, Card, Skeleton } from '@mui/material';
import styles from './styles.module.css';

export const VendorCardSkeleton = () => {
  return (
    <Box className={styles.vendor__card__container}>
      <Card className={styles.vendor__card}>
        <Box className={styles.vendor__media__container}>
          <Skeleton variant="circular" animation="wave" height={'inherit'} width={'inherit'} />
        </Box>
      </Card>
    </Box>
  );
};
