import { ExpandLess, ExpandMore, ImportExportOutlined } from '@mui/icons-material';
import { Box, Hidden, MenuItem, Select, SelectProps } from '@mui/material';
import { Typography } from 'src/index';
import { useState } from 'react';

type SortSelectProps = {
  value: any;
  availableSortOptions: any[];
  setOrderBy: (value: any) => void;
} & SelectProps;

export const Sort = ({ value, availableSortOptions, setOrderBy, ...props }: SortSelectProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleExpandClick = () => {
    const disabled = props.disabled;
    !disabled && setOpen(!open);
  };

  return (
    <Select
      {...props}
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      defaultValue={value}
      variant="standard"
      disableUnderline
      renderValue={(value: any) => {
        setOrderBy(value);
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <ImportExportOutlined className="text-midLightGray" />

            <Hidden mdDown>
              <Typography className="text-midLightGray" variant="body1">
                Sort by:
              </Typography>{' '}
              <Typography className="text-darkGray" variant="body1">
                {value}
              </Typography>
            </Hidden>

            <Box className="cursor-pointer text-midLightGray" onClick={() => handleExpandClick()}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Box>
        );
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => <></>}
    >
      {availableSortOptions &&
        availableSortOptions.map(
          option =>
            option && (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
