import { CategoryCardSkeleton, TypographySkeleton } from 'design-system';
import { Box, Grid } from '@mui/material';

export const CategorySliderSkeleton = () => {
  return (
    <>
      <Box className="mb-6 max-sm:mb-3">
        <TypographySkeleton variant="subtitle1" className="w-1/3 mb-6 max-sm:mb-3" />

        <Grid container spacing={{ xs: 1.5, md: 3 }} wrap="nowrap" sx={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>
          {Array(8)
            .fill(null)
            .map((_, idx) => (
              <Grid item key={`category-slider-skeleton-item-${idx}`}>
                <CategoryCardSkeleton />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};
