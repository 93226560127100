import { Box, Skeleton } from '@mui/material';
import { Typography } from 'src/index';

export const AccordionItemSkeleton = () => {
  return (
    <Box className="bg-lightGray px-6 py-4 rounded-2xl mb-3">
      <Typography variant="h3" className="w-1/3">
        <Skeleton variant="text" animation="wave" sx={{ backgroundColor: 'var(--gray)' }} />
      </Typography>
    </Box>
  );
};
