import {
  ControlledTextField,
  Typography,
  ControlledPhoneInput,
  ControlledAutocompleteSelect,
  LoadingButton,
} from 'design-system';
import { ShipmentTermEnum } from 'services/types/generic/enums';
import { useRequestSample } from './useRequestSample';
import { countries, toListItems } from 'utils';
import { Grid, Box } from '@mui/material';

export const PersonalAndShippingInfo = () => {
  const { isValid, control, handleSubmit, onSubmit, states, getValues, userEmail, loading } = useRequestSample();

  return (
    <>
      <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
        <Typography variant="h3" component="h3" className="text-darkGray mb-3">
          Personal and shipping info
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ControlledAutocompleteSelect
              name="shipmentTerm"
              control={control}
              options={toListItems(ShipmentTermEnum)}
              placeholder="Select shipping terms"
              inputLabelProps={{ required: true, children: 'Shipping Terms' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledAutocompleteSelect
              name="country"
              control={control}
              options={countries}
              placeholder="Select shipping destination"
              inputLabelProps={{ required: true, children: 'Shipping Destination' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledAutocompleteSelect
              name="state"
              control={control}
              options={states}
              disabled={!getValues('country')}
              placeholder="Select shipping state"
              inputLabelProps={{ required: true, children: 'Shipping State', disabled: !getValues('country') }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="adrs"
              control={control}
              inputLabelProps={{ required: true, children: 'Full Address' }}
              textFieldProps={{
                placeholder: 'Enter your full delivery information',
                InputProps: { inputProps: { maxLength: 125 } },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="email"
              control={control}
              inputLabelProps={{ required: true, children: 'Email', disabled: !!userEmail }}
              textFieldProps={{
                placeholder: 'Enter your email',
                disabled: !!userEmail,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledPhoneInput
              name="phone"
              control={control}
              inputLabelProps={{ required: true, children: 'Phone Number' }}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              name="description"
              control={control}
              inputLabelProps={{ children: 'Detailed Requirements' }}
              textFieldProps={{
                placeholder: 'I would like to request a sample of this product....',
                multiline: true,
                rows: 5,
              }}
            />
          </Grid>
        </Grid>
        <Box className="flex justify-center mt-10">
          <LoadingButton
            size="large"
            loading={loading}
            className="w-[390px]"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            label="Submit"
          />
        </Box>
      </Box>
    </>
  );
};
