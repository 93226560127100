import { ProductAttributeValueModelDto, ProductDetailsAttributeModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { Swiper, SwiperSlide, SwiperProps, SwiperClass } from 'swiper/react';
import { HTMLAttributes, useEffect, useRef } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { FreeMode } from 'swiper/modules';
import { Box } from '@mui/material';

type Props = {
  attr: ProductDetailsAttributeModelDto;
  activeValue: ProductAttributeValueModelDto | null | undefined;
  onAttributeChange: ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => void;
} & HTMLAttributes<HTMLDivElement>;

export const TextBar: React.FC<Props> = ({ attr, activeValue, onAttributeChange, ...props }) => {
  const swiperRef = useRef<SwiperClass>(null);

  const goToSlide = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  const handleSlideClick = (attrValue: ProductAttributeValueModelDto) => {
    onAttributeChange({ attr, attrValue });
  };

  useEffect(() => {
    const index =
      activeValue && attr.values?.findIndex((value: ProductAttributeValueModelDto) => value === activeValue);

    index && goToSlide(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeValue]);

  const sizeSwiperParams: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: 8,
    slidesPerView: 'auto',
    modules: [FreeMode],
    slideToClickedSlide: true,
    onSwiper: swiper => {
      (swiperRef as React.MutableRefObject<any>).current = swiper; // Ensure the ref is mutable
    },
  };
  return (
    <div {...props}>
      <Box className="mb-1">
        <Typography variant="subtitle2" className="text-darkGray">
          {attr.name} :{' '}
          <Typography variant="body2" className="text-midDarkGray" component="span">
            {activeValue?.name}
          </Typography>
        </Typography>
      </Box>

      <Swiper {...sizeSwiperParams}>
        {attr.values &&
          attr.values.map((attrValue, idx) => (
            <SwiperSlide
              key={`SwiperSlide-${idx}`}
              className={`${styles.swiper__slide}`}
              aria-selected={attrValue === activeValue}
              onClick={() => handleSlideClick(attrValue)}
            >
              <Box className={styles.outline__box}>
                <Box className={styles.border__box}>
                  <Box className={styles.content__box}>{attrValue?.name}</Box>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
