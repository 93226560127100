import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { getFileExtension } from 'src/utils/helpers/getFileExtension';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { FreeMode, Navigation } from 'swiper/modules';
import { Box, Typography } from '@mui/material';
import { isImageFormat } from 'src/utils';
import styles from './styles.module.css';
import { useRef, useState } from 'react';
import './styles.css';

type DropzoneFileSwiperProps = {
  previewFiles: File[];
  setPreviewFiles: any;
};

export const DropzoneFileSwiper = ({ previewFiles, setPreviewFiles }: DropzoneFileSwiperProps) => {
  const [isGradientNextVisible, setIsGradientNextVisible] = useState<boolean>(true);
  const [isGradientPrevVisible, setIsGradientPrevVisible] = useState<boolean>(false);

  const deleteFile = (index: number) => {
    const newPreviewFiles = [...previewFiles];
    newPreviewFiles.splice(index, 1);
    setPreviewFiles(newPreviewFiles);

    if (sliderRef.current) {
      if (sliderRef.current.isEnd) {
        setIsGradientNextVisible(false);
        setIsGradientPrevVisible(true);
      }
      if (sliderRef.current.isEnd) {
        setIsGradientNextVisible(true);
        setIsGradientPrevVisible(false);
      }
    }
  };

  const formatBytes = (bytes: any, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const sliderRef = useRef<SwiperClass>();

  const fileDropzoneSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: 12,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.custom-swiper-button-prev',
      nextEl: '.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: (swiper: SwiperClass) => {
      sliderRef.current = swiper;
    },
    onSlideChange: (swiper: SwiperClass) => {
      if (swiper.isEnd) {
        setIsGradientNextVisible(false);
        setIsGradientPrevVisible(true);
      }
      if (swiper.isBeginning) {
        setIsGradientNextVisible(true);
        setIsGradientPrevVisible(false);
      }
    },
    loop: false,
  };

  return (
    <>
      {previewFiles.length > 0 && (
        <div className="dropzone-swiper-with-arrows">
          <div className="custom-swiper-button-prev" onClick={() => sliderRef?.current?.slidePrev()}>
            {/* caret-right svg from public */}
            <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 17.5L15.5 12.5L10.5 7.5" />
            </svg>
          </div>

          <div className={styles.preview__container}>
            {isGradientNextVisible && <div className={styles.linear__gradient__next} />}
            {isGradientPrevVisible && <div className={styles.linear__gradient__prev} />}

            <Swiper {...fileDropzoneSwiperProps}>
              {previewFiles.map((file: File, index) => (
                <SwiperSlide style={{ width: 'auto' }} key={`preview-item-file-${file.name}`}>
                  <div key={`preview-item-file-${file.name}`} className={styles.preview__item}>
                    <div className={styles.image__name__container}>
                      {isImageFormat(file.name) && (
                        <img src={URL.createObjectURL(file)} alt={file.name} className={styles.item__image} />
                      )}
                      {!isImageFormat(file.name) && (
                        <Box className={styles.item__svg}>
                          <FileIcon
                            extension={getFileExtension(file.name)}
                            // @ts-ignore
                            {...defaultStyles[getFileExtension(file.name)]}
                          />
                        </Box>
                      )}

                      <div className="flex flex-col overflow-hidden">
                        <Typography variant="body2" className="text-midDarkGray truncate">
                          {file.name}
                        </Typography>

                        <Typography variant="caption" className="text-gray font-normal">
                          {formatBytes(file.size, 1)}
                        </Typography>
                      </div>
                    </div>

                    <button onClick={() => deleteFile(index)}>
                      {/* trash.svg from public folder */}
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_20589_6236)">
                          <path
                            d="M4.75 6.42383H20.75"
                            stroke="#FF0000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.9723 6.42383V18.8683C18.9723 19.7572 18.0834 20.6461 17.1945 20.6461H8.30561C7.41672 20.6461 6.52783 19.7572 6.52783 18.8683V6.42383"
                            stroke="#FF0000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.19446 6.4247V4.64692C9.19446 3.75803 10.0833 2.86914 10.9722 2.86914H14.5278C15.4167 2.86914 16.3056 3.75803 16.3056 4.64692V6.4247"
                            stroke="#FF0000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.9722 10.8691V16.2025"
                            stroke="#FF0000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.5278 10.8691V16.2025"
                            stroke="#FF0000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_20589_6236">
                            <rect x="0.25" width="24" height="24" rx="4" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="custom-swiper-button-next" onClick={() => sliderRef?.current?.slideNext()}>
            {/* caret-right svg from public */}
            <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 17.5L15.5 12.5L10.5 7.5" />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};
