import { DropzoneFileSwiper } from './components/dropzone-file-swiper';
import { Typography } from 'src/components/typographies/typography';
import { useCallback, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useMediaQuery } from '@mui/material';
import styles from './styles.module.css';
import { toast } from 'react-toastify';

type FileDropzoneProps = {
  acceptedFileTypes?: Accept;
  setFiles: any;
  icon: any;
  oneFile?: boolean;
  helperText: string;
  maxSize?: number;
  multiple?: boolean;
  maxFiles?: number;
};

export const FileDropzone = ({
  acceptedFileTypes,
  setFiles,
  icon,
  oneFile,
  helperText,
  maxSize,
  multiple,
  maxFiles,
}: FileDropzoneProps) => {
  const [previewFiles, setPreviewFiles] = useState<File[]>([]);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  useEffect(() => {
    setFiles(previewFiles);
  }, [previewFiles, setFiles]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const existingFiles = [];
      for (let file of acceptedFiles) {
        const previewFileNames = previewFiles.map(f => f.name);
        if (previewFileNames.includes(file?.name)) {
          existingFiles.push(file?.name);
        }
      }
      if (existingFiles.length) {
        toast.error(`The file/s already exist/s.`);
        return;
      }
      if (oneFile) {
        setPreviewFiles([...acceptedFiles]);
      } else if (!maxFiles || previewFiles.length + acceptedFiles.length <= maxFiles)
        setPreviewFiles([...previewFiles, ...acceptedFiles]);
      else toast.error('Too many files, Please reduce the files number.');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oneFile, previewFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxSize,
    multiple,
    maxFiles,
    onDropRejected: fileRejections => {
      switch (fileRejections[0].errors[0].code) {
        case 'file-too-large':
          toast.error('The file is too large, Please reduce the file size.');
          break;
        case 'file-invalid-type':
          toast.error('The file format is not supported.');
          break;
        case 'too-many-files':
          toast.error('Too many files, Please reduce the files number.');
          break;
        default:
          toast.error('Un-expected Error.');
          break;
      }
    },
  });

  return (
    <>
      <DropzoneFileSwiper previewFiles={previewFiles} setPreviewFiles={setPreviewFiles} />

      <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {icon}
        <Typography variant="subtitle1" className="text-deepYellow my-4 max-sm:my-3">
          {isTouchDevice ? 'Upload document' : 'Drag & Drop'}
        </Typography>
        <Typography variant="body2" className="text-midLightGray text-center">
          {helperText}
        </Typography>
      </div>
    </>
  );
};
