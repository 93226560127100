import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { NopSlider, NopResultsHeader, NopPageSize, NopSort, NopHits } from 'components';
import { Button, Loader, Pagination, Typography, VendorCard } from 'design-system';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useVendorDetails } from './useVendorDetails';
import { ArrowForward } from '@mui/icons-material';
import vrImg from '../../assets/images/VR.svg';
import { Navigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { htmlToText } from 'utils';

export const VendorDetails = () => {
  const {
    vendorDetails,
    onContactSeller,
    isTouchDevice,
    descriptionTextResp,
    fullDescriptionOpen,
    setFullDescriptionOpen,
    isErrorVendorDetails,
    availableSortOptions,
    handleFilterRequest,
    pageSizeOptions,
    setOrderBy,
    setPageSize,
    vendorProductsResponse,
    totalItems,
    totalPages,
    productsRef,
    priceRange,
    setPriceRange,
    vendorId,
    pageSize,
    orderBy,
    pageNumber,
    setPageNumber,
    isVendorDetailsLoading,
  } = useVendorDetails();

  if (isErrorVendorDetails) return <Navigate to="/error" />;
  if (isVendorDetailsLoading) return <Loader />;

  return (
    <>
      <Helmet>
        <title>{vendorDetails?.metaTitle || vendorDetails?.name}</title>
        <meta name="description" content={vendorDetails?.metaDescription ? vendorDetails.metaDescription : ''} />
        <meta name="keywords" content={vendorDetails?.metaKeywords ? vendorDetails?.metaKeywords : ''} />
      </Helmet>

      <div
        className={classNames(styles.vendor__section, 'responsive-full-width-ignore-container-padding')}
        key={`Vendor ${vendorDetails?.id}`}
      >
        <div className={styles.vendor__section__info}>
          <VendorCard
            vendor={{
              id: +vendorId!,
              pictureModel: {
                fullSizeImageUrl: vendorDetails?.pictureModel.fullSizeImageUrl || '',
                alternateText: vendorDetails?.pictureModel.alternateText || '',
              },
            }}
            sx={{ borderRadius: '8px !important', padding: '0 !important', border: '0 !important' }}
          />

          <Box className="flex flex-col">
            <div className="mb-3 max-sm:mb-1">
              <Typography variant="h4" component="h4" className="text-white max-sm:mb-1 line-clamp-1">
                {vendorDetails?.name}
              </Typography>

              {vendorDetails?.since && (
                <Typography variant="caption" className="text-secondaryLight">
                  Since {vendorDetails?.since}
                </Typography>
              )}
            </div>

            {vendorDetails?.description && (
              <>
                {!isTouchDevice && (
                  <Typography variant="body2" className="text-white">
                    {htmlToText(vendorDetails?.description)}
                  </Typography>
                )}

                {isTouchDevice && (
                  <Typography variant="body2" className="text-white">
                    {descriptionTextResp}
                    <Box className="inline-flex items-center">
                      <Typography
                        variant="body2"
                        className="underline text-white cursor-pointer font-bold ms-2"
                        onClick={() => setFullDescriptionOpen(!fullDescriptionOpen)}
                        component="span"
                      >
                        {fullDescriptionOpen ? 'View Less' : 'View More'}
                      </Typography>
                      <Box component="span">
                        {fullDescriptionOpen ? (
                          <KeyboardArrowUp style={{ width: '15px', height: '15px' }} />
                        ) : (
                          <KeyboardArrowDown style={{ width: '15px', height: '15px' }} />
                        )}
                      </Box>
                    </Box>
                  </Typography>
                )}
              </>
            )}
          </Box>
        </div>

        <Box className={styles.vendor__section__actions}>
          <Button
            size="small"
            color="inherit"
            onClick={onContactSeller}
            endIcon={<ArrowForward />}
            className={styles.vendor__contact}
            label="Negotiate"
          />
          {vendorDetails?.vrLink && (
            <a href={vendorDetails?.vrLink} target="_blank" rel="noopener noreferrer" className={styles.vr}>
              <Button
                size="small"
                color="inherit"
                className={styles.vendor__vr}
                endIcon={<img src={vrImg} alt="vr" />}
                label="View VR"
              />
            </a>
          )}
        </Box>
      </div>

      <div ref={productsRef}>
        <Grid container spacing={{ xs: 0, md: 3 }} className="mt-6 max-sm:mt-3">
          {!isTouchDevice && (
            <Grid item xs={12} lg={2.5}>
              <Box className={styles.filter__box}>
                <Typography variant="h4" component="h4" className="text-darkGray mb-8">
                  Filter Items
                </Typography>

                <Typography variant="h6" component="h6" className="text-darkGray mb-8">
                  Price Range
                </Typography>

                <NopSlider
                  min={0}
                  max={vendorProductsResponse?.catalogProductsModel?.priceRangeFilter?.availablePriceRange.to || 10000}
                  value={priceRange}
                  onChange={setPriceRange}
                />

                <Button
                  size="small"
                  className="mt-5"
                  startIcon={<FilterIcon stroke="var(--white)" />}
                  onClick={handleFilterRequest}
                  label="Filter"
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} lg={9.5}>
            <NopResultsHeader
              itemsCount={totalItems}
              pageSizeComponent={
                <NopPageSize
                  key={`vendor-${vendorId}-page-size`}
                  pageSize={pageSize.toString()}
                  pageSizeOptions={pageSizeOptions}
                  setPageSize={setPageSize}
                />
              }
              sortComponent={
                <NopSort
                  key={`vendor-${vendorId}-sort`}
                  currentValue={orderBy}
                  availableSortOptions={availableSortOptions}
                  setOrderBy={setOrderBy}
                />
              }
              onFilter={handleFilterRequest}
              categoryProductsResponse={vendorProductsResponse}
              priceRange={priceRange}
              setPriceRange={setPriceRange}
            />

            {vendorProductsResponse && <NopHits hits={vendorProductsResponse?.catalogProductsModel?.products} />}
            {!vendorProductsResponse && <Loader />}

            <Box className="my-[60px]">
              <Pagination
                key={`category-${vendorId}-pagination`}
                count={totalPages}
                page={pageNumber}
                handleChangePage={setPageNumber}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
