import styles from './styles.module.css';
import { Box } from '@mui/material';

export const CategoryCardSkeleton = () => {
  return (
    <Box className={styles.category__card}>
      <Box className={styles.category__media__container} />
    </Box>
  );
};
