import {
  AlgoliaClearRefinements,
  AlgoliaRefinementList,
  AlgoliaResultsHeader,
  AlgoliaHitsPerPage,
  AlgoliaPagination,
  AlgoliaSlider,
  AlgoliaHits,
  AlgoliaSort,
} from 'components';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import FilterButtons from 'pages/product-details/components/filter-buttons';
import { useSearchProducts } from './useSearchProducts';
import { Button, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box, Grid } from '@mui/material';
import { HasNoResult } from 'components';
import { Link } from 'react-router-dom';

export const Products = () => {
  const {
    results,
    clearRefine,
    location,
    search,
    filterDrawerOpen,
    setFilterDrawerOpen,
    isTouchDevice,
    options,
    refineSortBy,
    canRefine,
    currentRefinement,
  } = useSearchProducts();

  const getHasNoResults = () => {
    return results.nbHits === 0 || (!location.state?.searchName && !search);
  };

  return (
    <>
      {getHasNoResults() && (
        <HasNoResult
          title="It seems like your search is not available right now!"
          description="Please provide us with more details by submitting a"
          buttonComponent={
            <Link to="/rfq/submit/first-step" id="RequestForQuotationLink">
              <Button
                label="Request for Quotation"
                startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />}
              />
            </Link>
          }
        />
      )}

      {!getHasNoResults() && (
        <>
          <Grid container spacing={{ xs: 0, md: 3 }} className="mt-6 max-sm:mt-5">
            <Grid
              item
              xs={12}
              lg={2.5}
              className={`pt-0 ${isTouchDevice && !filterDrawerOpen && 'hidden'} ${
                isTouchDevice && filterDrawerOpen && 'pb-[72px]'
              }`}
            >
              <Box className={styles.filter__box}>
                <Box className="flex justify-between items-center mb-4">
                  <Typography variant="h5" component="h5" className="capitalize text-darkGray">
                    Filter items
                  </Typography>

                  {!isTouchDevice && <AlgoliaClearRefinements />}

                  {isTouchDevice && (
                    <CloseIcon className="w-6 h-6 fill-midLightGray" onClick={() => setFilterDrawerOpen(false)} />
                  )}
                </Box>

                <AlgoliaSlider attribute="price" />

                <AlgoliaRefinementList
                  attribute="category"
                  searchable={true}
                  showMore={true}
                  limit={5}
                  sortBy={['name:asc']}
                  operator="or"
                  showMoreLimit={9999}
                />

                <AlgoliaRefinementList
                  attribute="vendor"
                  searchable={true}
                  showMore={true}
                  limit={5}
                  sortBy={['name:asc']}
                  operator="or"
                  showMoreLimit={9999}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={9.5} className={`pt-0 ${isTouchDevice && filterDrawerOpen ? 'hidden' : ''}`}>
              <AlgoliaResultsHeader
                query={location.state?.searchName}
                itemsCount={+results.nbHits}
                pageSizeComponent={<AlgoliaHitsPerPage />}
                sortComponent={
                  <AlgoliaSort
                    availableOptions={options}
                    currentValue={currentRefinement}
                    refine={refineSortBy}
                    canRefine={canRefine}
                    defaultValue={process.env.REACT_APP_ALGOLIA_INDEX}
                  />
                }
                filterDrawerOpen={filterDrawerOpen}
                setFilterDrawerOpen={setFilterDrawerOpen}
              />

              <AlgoliaHits />
              <Box className={isTouchDevice ? 'mt-6 mb-12' : 'my-[60px]'}>
                <AlgoliaPagination />
              </Box>
            </Grid>
          </Grid>

          {isTouchDevice && filterDrawerOpen && (
            <>
              {/* <Box className="fixed w-full start-0 bottom-0 py-4 px-5 bg-white z-[999]">
                <Grid container spacing={1.5}>
                  <Grid item xs={6}>
                    <Button size="small" variant="outlined" className="w-full" onClick={refine} label="Reset" />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      variant="contained"
                      color="magentaPink"
                      className="w-full"
                      onClick={() => setFilterDrawerOpen(false)}
                      label="See Results"
                    />
                  </Grid>
                </Grid>
              </Box> */}

              <FilterButtons onReset={clearRefine} onApply={() => setFilterDrawerOpen(false)} />
            </>
          )}
        </>
      )}
    </>
  );
};
