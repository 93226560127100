import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Card, Box } from '@mui/material';

export const LargeCategoryCard = ({ category }: { category: CategorySimpleModelDto }) => {
  return (
    <Card className={styles.category__card}>
      <Box
        className={styles.category__image}
        sx={{ backgroundImage: `url(${category?.pictureModel?.fullSizeImageUrl})` }}
      >
        <Box className={styles.category__tile__button}>
          <img src={category?.iconModel?.fullSizeImageUrl || ''} alt="" />
          <Typography variant="subtitle1">{category.name}</Typography>
        </Box>
      </Box>
    </Card>
  );
};
