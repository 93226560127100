import { Box, FormGroup, Skeleton } from '@mui/material';
import { TypographySkeleton } from 'design-system';
export const NopCheckboxFilterSkeleton = () => {
  return (
    <FormGroup className="mt-4">
      <TypographySkeleton variant="subtitle1" className="mb-3" />

      {Array(3)
        .fill(null)
        .map((_, idx) => (
          <Box
            sx={{ width: '100%', height: '24px', padding: '2px', display: 'flex', marginBottom: '16px', gap: '10px' }}
            key={`nop-checkbox-filter-skeleton-item-${idx}`}
          >
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ width: '18px', overflow: 'unset', aspectRatio: '1/1' }}
            />
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%' }} />
          </Box>
        ))}
    </FormGroup>
  );
};
