import {
  ProductAttributeValueModelDto,
  ProductDetailsAttributeChangeResponse,
  ProductDetailsAttributeModelDto,
  ProductDetailsModelDto,
} from 'otl-codegen/dist/marketplace-frontend';
import { updateProductDetailsAttribute } from 'services/orchestrator.service';
import { ProductAttributeEnum } from 'services/types/generic/enums';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useRef, useState } from 'react';
import { findProductAttributeById } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

type MainDetailsProps = {
  productDetailsModel: ProductDetailsModelDto;
  onProductAttributeChange?: Function;
};
export const useMainDetails = ({ productDetailsModel, onProductAttributeChange }: MainDetailsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const [productAttributeChange, setProductAttributeChange] = useState<ProductDetailsAttributeChangeResponse>();
  const [productId, setProductId] = useState<number>();
  const [attributeChangeBody, setAttributeChangeBody] = useState<Record<string, number>>({});
  const [copied, setCopied] = useState<Boolean>(false);
  const [contentScrolled, setContentScrolled] = useState<Boolean>(false);
  const [activeColorAttrValue, setActiveColorAttrValue] = useState<ProductAttributeValueModelDto | null>();
  const [activeSizeAttrValue, setActiveSizeAttrValue] = useState<ProductAttributeValueModelDto | null>();
  const [activeFinishAttrValue, setActiveFinishAttrValue] = useState<ProductAttributeValueModelDto | null>();
  const [activeThicknessAttrValue, setActiveThicknessAttrValue] = useState<ProductAttributeValueModelDto | null>();
  const [getSampleDrawerOpen, setGetSampleDrawerOpen] = useState<boolean>(false);

  const { name, shortDescription, sku, tierPrices, moq } = productDetailsModel;

  const requestSampleFlagEnabled = useFeatureFlagEnabled('get-sample');

  const contentRef = useRef<HTMLDivElement>(null);

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const navigate = useNavigate();
  const sharePopoverOpen = Boolean(anchorEl);
  const id = sharePopoverOpen ? 'simple-popover' : undefined;
  const url = window.location.href;
  const { productAttributes } = productDetailsModel;

  const colorsAttribute = productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Colors);
  const sizeAttribute = productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Size);
  const thicknessAttribute =
    productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Thickness);
  const finishAttribute = productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Finish);

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if (isTouchDevice && navigator.share) {
      navigator.share({
        title: `${productDetailsModel.seName}`,
        text: `Check out ${productDetailsModel.seName} on `,
        url: document.location.href,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);

    setTimeout(() => {
      setCopied(false);
    }, 300);
  };

  const setSuitableAttrState = ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => {
    switch (attr.productAttributeId) {
      case 1:
        setActiveColorAttrValue(attrValue);
        break;
      case 2:
        setActiveSizeAttrValue(attrValue);
        break;
      case 3:
        setActiveThicknessAttrValue(attrValue);
        break;
      case 4:
        setActiveFinishAttrValue(attrValue);
        break;

      default:
        break;
    }
  };

  const callAttributeChange = ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => {
    setSuitableAttrState({ attr, attrValue });
    setAttributeChangeBody(prevBody => ({
      ...prevBody,
      [`product_attribute_${attr.id}`]: attrValue.id,
    }));
    setProductId(attr.productId);
  };

  const updateProductAttribute = async (productId: number) => {
    const [{ data: productAttributeChangeResponse }] = await Promise.all([
      updateProductDetailsAttribute({
        productId,
        body: attributeChangeBody,
      }),
    ]);
    setProductAttributeChange(productAttributeChangeResponse);
    onProductAttributeChange && onProductAttributeChange(productAttributeChangeResponse);
  };

  const toggleGetSampleDrawer = (state: boolean) => {
    setGetSampleDrawerOpen(state);
  };

  useEffect(() => {
    if (productId) updateProductAttribute(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeChangeBody, productId]);

  useEffect(() => {
    const container = contentRef.current;

    const handleScroll = () => {
      const scrollTop = container?.scrollTop || 0;
      setContentScrolled(scrollTop > 0);
    };

    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (getSampleDrawerOpen) {
      const drawerElement = document.getElementById('gs-drawer-container');
      if (drawerElement) {
        drawerElement.scrollTop = 0;
      }
    }
  }, [getSampleDrawerOpen]);

  return {
    anchorEl,
    sharePopoverOpen,
    handleShareClick,
    handleClose,
    id,
    url,
    navigate,
    colorsAttribute,
    sizeAttribute,
    thicknessAttribute,
    finishAttribute,
    contentRef,
    callAttributeChange,
    productAttributeChange,

    copied,
    setCopied,
    contentScrolled,
    isTouchDevice,

    productName: name,
    shortDescription,
    sku,
    tierPrices,
    moq,

    requestSampleFlagEnabled,

    getSampleDrawerOpen,
    toggleGetSampleDrawer,

    activeColorAttrValue,
    setActiveColorAttrValue,
    activeSizeAttrValue,
    setActiveSizeAttrValue,
    activeThicknessAttrValue,
    setActiveThicknessAttrValue,
    activeFinishAttrValue,
    setActiveFinishAttrValue,
  };
};
