import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { ProductOverviewModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ProductCard, Typography } from 'design-system';
import { FreeMode, Navigation } from 'swiper/modules';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { sendEventToGTM } from 'utils';
import { useRef } from 'react';

type Props = {
  products: ProductOverviewModelDto[] | undefined;
};

export const BestSellingProducts = ({ products }: Props) => {
  const sliderRef = useRef<SwiperClass>();
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const bestSellingProductsSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: isDownMd ? 12 : 24,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.products-swiper.custom-swiper-button-prev',
      nextEl: '.products-swiper.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  if (!products) return <></>;

  return (
    <>
      {products.length >= 5 && (
        <>
          <Typography variant="h3" component="h3" className="text-darkGray mb-4 max-sm:mb-3">
            Best Selling
          </Typography>

          <div className="swiper-with-arrows">
            <div className="products-swiper custom-swiper-button-prev" onClick={() => sliderRef?.current?.slidePrev()}>
              <CaretRight />
            </div>

            <Swiper {...bestSellingProductsSwiperProps}>
              {products.map(product => (
                <SwiperSlide style={{ padding: '2px', width: 'auto' }} key={`best-selling-slide-${product?.id}`}>
                  <Link
                    key={`product-#${product.id}`}
                    to={`/product-details/${product.id}/${product.seName}`}
                    state={{ fromSearch: false }}
                    onClick={() =>
                      sendEventToGTM('best_selling_click', {
                        product_id: product.id,
                      })
                    }
                  >
                    <Box className={styles.product__card}>
                      <ProductCard
                        key={product.id}
                        pName={product.name}
                        image={product.pictureModels![0].fullSizeImageUrl}
                        price={product.productPrice.price}
                        unit={product.unit}
                      />
                    </Box>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="products-swiper custom-swiper-button-next" onClick={() => sliderRef?.current?.slideNext()}>
              <CaretRight />
            </div>
          </div>
        </>
      )}
    </>
  );
};
