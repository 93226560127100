import { ProductCardSkeleton, TypographySkeleton } from 'design-system';
import { Grid } from '@mui/material';

export const BestSellingProductsSkeleton = () => {
  return (
    <>
      <TypographySkeleton variant="h3" className="w-1/3 mb-4 max-sm:mb-3" />

      <Grid container spacing={{ xs: 1.5, md: 3 }} wrap="nowrap" sx={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>
        {Array(5)
          .fill(null)
          .map((_, idx) => (
            <Grid item key={`best-selling-product-skeleton-item-${idx}`}>
              <ProductCardSkeleton />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
