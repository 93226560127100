import { HeaderHandler } from './components/headers/header-handler';
import { FooterHandler } from './components/footers/footer-handler';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useCustomAuth, useScrollToTop } from 'hooks';
import { AppContainer } from 'design-system';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

type LayoutProps = {
  containerFloating?: boolean;
  hasFooter?: boolean;
};

export const Layout = ({ containerFloating, hasFooter = true }: LayoutProps) => {
  useScrollToTop();
  const { isAuthenticated } = useCustomAuth();
  if (isAuthenticated) {
    document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  } else {
    document.querySelector('#hubspot-messages-iframe-container')?.classList.remove('translate-x-full');
  }

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      <Helmet>
        <title>otrovato, B2B marketplace in Africa</title>
        <meta
          name="description"
          content="otrovato is designed to help buyers and sellers connect while simplifying the process of trading to make it accessible, efficient and secure all over Africa."
        />
        <meta
          name="keywords"
          content="B2B marketplace, Trade in Africa, Trade in Kenya, African Manufacturers and Suppliers, African Exporters and African Importers, African Trading Platform, Import products from Africa, Top B2B portals, best  African Suppliers, best B2B suppliers, b2b trade portal, top 10 b2b seller, food b2b marketplace, best b2b portals, furniture b2b marketplace, construction b2b marketplace, b2b food suppliers, b2b exporters, chemicals b2b marketplace"
        />
      </Helmet>

      {!containerFloating && (
        <>
          <Box className="pb-[100px] max-sm:pb-[50px] bg-background">
            <HeaderHandler />
            <AppContainer className="relative" id="router-outlet">
              <Box className="mt-6 min-h-[66vh] max-sm:min-h-[40vh] max-sm:mt-5">
                <Outlet />
              </Box>
            </AppContainer>
          </Box>
          <FooterHandler hasFooter={hasFooter} />
        </>
      )}

      {containerFloating && (
        <Box className={isDownMd ? ' bg-background' : ''}>
          <HeaderHandler />
          <Box id="router-outlet">
            <Outlet />
          </Box>
          <FooterHandler hasFooter={hasFooter} />
        </Box>
      )}
    </>
  );
};
