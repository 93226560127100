import { LargeCategoryCardSkeleton } from '../large-category-card-skeleton';
import { TypographySkeleton } from 'design-system';
import { Box, Grid } from '@mui/material';

export const CategoryBestSellerSkeleton = () => {
  return (
    <>
      <Box className="flex justify-between">
        <TypographySkeleton variant="h3" className="w-1/3 mb-4 max-sm:mb-3" />
        <TypographySkeleton variant="h3" className="w-1/6 mb-4 max-sm:mb-3" />
      </Box>

      <Grid container spacing={{ xs: 1.5, md: 3 }} wrap="nowrap" sx={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>
        {Array(4)
          .fill(null)
          .map((_, idx) => (
            <Grid item key={`category-best-seller-skeleton-item-${idx}`}>
              <LargeCategoryCardSkeleton />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
