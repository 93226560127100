import {
  ProductDetailsAttributeModelDto,
  ProductAttributeValueModelDto,
  ProductDetailsAttributeChangeResponse,
  PictureModelDto,
} from 'otl-codegen/dist/marketplace-frontend';
import { getProductById, updateProductDetailsAttribute } from 'services/orchestrator.service';
import { useMainDetails } from '../main-details/useMainDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getData } from 'utils';

export const useGetSampleDrawer = ({
  selectedActiveColorAttrValue,
  selectedActiveSizeAttrValue,
  selectedActiveThicknessAttrValue,
  selectedActiveFinishAttrValue,
  selectedCurrentPictureIndex,
  toggleGetSampleDrawer,
}) => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const [attributeChangeBody, setAttributeChangeBody] = useState<Record<string, number>>({});
  const [productIdSt, setProductIdSt] = useState<number>();
  const [productAttributeChange, setProductAttributeChange] = useState<ProductDetailsAttributeChangeResponse>();
  const [currentPictureIndex, setCurrentPictureIndex] = useState<number>(selectedCurrentPictureIndex);

  const { data: productDetails } = useQuery({
    queryKey: ['productDetails', productId],
    queryFn: getData(() => getProductById(+productId!)),
    enabled: !!productId,
  });

  const {
    activeColorAttrValue,
    activeSizeAttrValue,
    activeThicknessAttrValue,
    activeFinishAttrValue,
    setActiveColorAttrValue,
    setActiveSizeAttrValue,
    setActiveThicknessAttrValue,
    setActiveFinishAttrValue,
    colorsAttribute,
    sizeAttribute,
    finishAttribute,
    thicknessAttribute,
  } = useMainDetails({
    productDetailsModel: productDetails?.productDetailsModel!,
  });

  const updateProductAttribute = async (productId: number) => {
    const [{ data: productAttributeChangeResponse }] = await Promise.all([
      updateProductDetailsAttribute({
        productId,
        body: attributeChangeBody,
      }),
    ]);
    setProductAttributeChange(productAttributeChangeResponse);
  };

  const setSuitableAttrState = ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => {
    switch (attr.productAttributeId) {
      case 1:
        setActiveColorAttrValue(attrValue);
        break;
      case 2:
        setActiveSizeAttrValue(attrValue);
        break;
      case 3:
        setActiveThicknessAttrValue(attrValue);
        break;
      case 4:
        setActiveFinishAttrValue(attrValue);
        break;

      default:
        break;
    }
  };

  const callAttributeChange = ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => {
    setSuitableAttrState({ attr, attrValue });
    setAttributeChangeBody(prevBody => ({
      ...prevBody,
      [`product_attribute_${attr.id}`]: attrValue.id,
    }));
    setProductIdSt(attr.productId);
  };

  const onNextGetSample = () => {
    const variations = {
      color: activeColorAttrValue,
      size: activeSizeAttrValue,
      thickness: activeThicknessAttrValue,
      finish: activeFinishAttrValue,
      selectedImage: getCurrentPictureUrl('url'),
    };

    const data = {
      productDetails: JSON.stringify(productDetails?.productDetailsModel),
      variations: JSON.stringify(variations),
    };
    localStorage.setItem('request-sample-data', JSON.stringify(data));
    navigate('/request-sample/personal-shipping-info');
  };

  const onClose = () => {
    toggleGetSampleDrawer(false);
    setActiveColorAttrValue(selectedActiveColorAttrValue);
    setActiveSizeAttrValue(selectedActiveSizeAttrValue);
    setActiveThicknessAttrValue(selectedActiveThicknessAttrValue);
    setActiveFinishAttrValue(selectedActiveFinishAttrValue);
    setCurrentPictureIndex(selectedCurrentPictureIndex);
  };

  const getCurrentPictureUrl = (type: 'url' | 'alt') => {
    if (type === 'url') {
      if (currentPictureIndex) {
        return productDetails?.productDetailsModel?.pictureModels?.[currentPictureIndex]?.fullSizeImageUrl;
      } else return productDetails?.productDetailsModel?.defaultPictureModel.fullSizeImageUrl;
    } else {
      if (currentPictureIndex) {
        return productDetails?.productDetailsModel?.pictureModels?.[currentPictureIndex]?.alternateText;
      } else return productDetails?.productDetailsModel?.defaultPictureModel.alternateText;
    }
  };

  const findPictureIndex = (
    pictures: PictureModelDto[],
    productAttrChange: ProductDetailsAttributeChangeResponse,
  ): number => {
    if (productAttrChange.pictureFullSizeUrl) {
      return pictures.findIndex(picture => picture.fullSizeImageUrl === productAttrChange.pictureFullSizeUrl);
    } else return 0;
  };

  useEffect(() => {
    setActiveColorAttrValue(selectedActiveColorAttrValue);
    setActiveSizeAttrValue(selectedActiveSizeAttrValue);
    setActiveThicknessAttrValue(selectedActiveThicknessAttrValue);
    setActiveFinishAttrValue(selectedActiveFinishAttrValue);
  }, [
    selectedActiveColorAttrValue,
    selectedActiveFinishAttrValue,
    selectedActiveSizeAttrValue,
    selectedActiveThicknessAttrValue,
    setActiveColorAttrValue,
    setActiveFinishAttrValue,
    setActiveSizeAttrValue,
    setActiveThicknessAttrValue,
  ]);

  useEffect(() => {
    if (productIdSt) updateProductAttribute(productIdSt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeChangeBody, productIdSt]);

  useEffect(() => {
    const pictureModels = productDetails?.productDetailsModel.pictureModels;
    if (productAttributeChange && pictureModels) {
      const index = findPictureIndex(pictureModels, productAttributeChange);
      setCurrentPictureIndex(index);
    }
  }, [productDetails?.productDetailsModel.pictureModels, productAttributeChange]);

  useEffect(() => {
    setCurrentPictureIndex(selectedCurrentPictureIndex);
  }, [selectedCurrentPictureIndex]);

  return {
    productDetails,
    callAttributeChange,
    productAttributeChange,
    onNextGetSample,
    colorsAttribute,
    sizeAttribute,
    finishAttribute,
    thicknessAttribute,
    activeColorAttrValue,
    activeSizeAttrValue,
    activeThicknessAttrValue,
    activeFinishAttrValue,
    currentPictureIndex,
    onClose,
    getCurrentPictureUrl,
  };
};
