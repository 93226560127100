import { Box, Skeleton } from '@mui/material';
import styles from './styles.module.scss';

export const NopResultsHeaderSkeleton = () => {
  return (
    <Box className={styles.results__header}>
      <Skeleton variant="rounded" animation="wave" sx={{ height: '70px' }} />
    </Box>
  );
};
