import { Button, ControlledTextField, Typography, ControlledPhoneInput } from 'design-system';
import { useContactUs } from './useContactUs';
import { Grid, Box } from '@mui/material';
import { Helmet } from 'react-helmet';

export const ContactUs = () => {
  const { isValid, control, handleSubmit, onSubmit } = useContactUs();

  return (
    <>
      <Helmet>
        <title>Get in Touch</title>
        <meta name="description" content="Have a question or need assistance? Contact us for support." />
        <meta
          name="keywords"
          content="cheapest products in Africa, african b2b marketplace, Trading Customer Support, African B2B Marketplace Assistance, Partnership Opportunities, African Trade Platform Contact, kenya Supplier Support, Importer/Exporter Contact, African online B2B marketplace. , AfCFTA contact, African B2B Marketplace"
        />
      </Helmet>

      <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
        <Box className="mb-6">
          <Typography variant="h3" component="h3" className="text-darkGray">
            Contact Us
          </Typography>
          <Typography variant="body1" className="text-midLightGray">
            Let us know how we can help!
          </Typography>
        </Box>

        <Grid container spacing={3} className="mt-6 max-sm:mt-0">
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="firstname"
              control={control}
              inputLabelProps={{ required: true, children: 'First Name' }}
              textFieldProps={{ placeholder: 'Enter your first name' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="lastname"
              control={control}
              inputLabelProps={{ required: true, children: 'Last Name' }}
              textFieldProps={{ placeholder: 'Enter your Last name' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="email"
              control={control}
              inputLabelProps={{ required: true, children: 'Email' }}
              textFieldProps={{ placeholder: 'Enter your Email' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledPhoneInput
              name="phone"
              control={control}
              inputLabelProps={{ required: true, children: 'Phone Number' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="company"
              control={control}
              inputLabelProps={{ required: true, children: 'Company Name' }}
              textFieldProps={{ placeholder: 'Enter your Company name' }}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              name="message"
              control={control}
              inputLabelProps={{ required: true, children: 'Message' }}
              textFieldProps={{ placeholder: 'Leave your message', multiline: true, rows: 5 }}
            />
          </Grid>
        </Grid>

        <Box className="flex justify-center mt-12">
          <Button
            size="large"
            className="my-4 w-[390px]"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            label="Submit"
          />
        </Box>
      </Box>
    </>
  );
};
