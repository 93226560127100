import { Button, SentSuccessfully, Typography } from 'design-system';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect } from 'react';

export const GetSampleSubmitSuccess = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Get sample submission', link: '/request-sample/submit-success' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0 pt-2">
      <Box className="flex flex-col justify-center items-center">
        <Box className={styles.lottie__container}>
          <SentSuccessfully />
        </Box>

        <Typography variant="h4" component="h4" className="text-darkGray mb-2">
          Your request submitted successfully
        </Typography>

        <Typography variant="body1" className="text-center text-midLightGray mb-4">
          The vendor will review it and respond through your 'Manage Requests' page.
        </Typography>

        <Link to="/request-sample">
          <Button label="Manage your requests" />
        </Link>
      </Box>
    </Box>
  );
};
