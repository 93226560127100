import { ReactComponent as DownloadIcon } from 'design-system/public/icons/Download.svg';
import { useRequestSampleDetails } from './useRequestSampleDetails';
import { Loader, StatusChip, Typography } from 'design-system';
import { Box, Grid, Link } from '@mui/material';
import styles from './styles.module.scss';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import moment from 'moment';

const KeyValue: React.FC<PropsWithChildren<any>> = ({
  title,
  value,
  lineClamp = true,
  ...props
}: {
  title: string;
  value?: string;
  lineClamp?: boolean;
}) => {
  return (
    <Grid container {...props}>
      <Grid item xs={5} md={5} lg={4}>
        <Typography variant="body2" className="text-midLightGray">
          {title}:&nbsp;
        </Typography>
      </Grid>
      <Grid item xs={7} md={7} lg={8}>
        <Typography variant="body2" className={`text-darkGray ${lineClamp && 'line-clamp-1'}`}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
export const RequestSampleDetails = () => {
  const {
    id,
    requestSampleDetails,

    isTouchDevice,
    isMainHovered,
    setIsMainHovered,
  } = useRequestSampleDetails();

  if (!id || !requestSampleDetails) return <Loader />;

  return (
    <>
      <Box className="flex justify-between items-center mb-6">
        <Box className="flex items-center">
          <Typography variant="body1" className="text-midLightGray" component="span">
            Sample ID:&nbsp;
          </Typography>

          <Typography variant="subtitle1" className="text-darkGray" component="span">
            {requestSampleDetails?.shortID}
          </Typography>

          <Box className="inline-block ms-3 cursor-pointer">
            <StatusChip
              status={requestSampleDetails?.status}
              sx={{
                width: { xs: '74px', md: '94px' },
                height: { xs: '28px', md: '34px' },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classNames(styles.sample__content, 'responsive-full-width-ignore-container-padding')}>
        <Grid container spacing={{ xs: 2, lg: 3 }}>
          <Grid item xs={4} sm={3} lg={2}>
            <Box
              className={styles.main__file__container}
              onMouseEnter={() => setIsMainHovered(true)}
              onMouseLeave={() => setIsMainHovered(false)}
            >
              <img src={JSON.parse(requestSampleDetails.variations).selectedImage} alt="" />

              {isTouchDevice && (
                <Link
                  href={JSON.parse(requestSampleDetails.variations).selectedImage}
                  className={styles.responsive__main__link}
                />
              )}

              {!isTouchDevice && isMainHovered && (
                <a className={styles.download__button} href={JSON.parse(requestSampleDetails.variations).selectedImage}>
                  <Typography variant="overline" component="span">
                    Download
                  </Typography>
                  <DownloadIcon />
                </a>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <Typography variant="subtitle1" className="text-darkGray mb-3">
              Product Details :
            </Typography>

            <KeyValue title="Product Name" value={requestSampleDetails?.productName} className="mb-2" />
            <KeyValue
              title="Vendor Name"
              value={JSON.parse(requestSampleDetails?.productDetails).vendorModel.name}
              className="mb-2"
            />
            <KeyValue
              title="Creation Date"
              value={moment(requestSampleDetails?.CreatedAt).utc().format('MMMM DD, YYYY')}
              lineClamp={false}
              className="mb-2"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <Typography variant="subtitle1" className="text-darkGray mb-3">
              Request Details :
            </Typography>

            <KeyValue title="Shipping Terms" value={requestSampleDetails?.shipmentTerm} className="mb-2" />
            <KeyValue title="Shipping Destination" value={requestSampleDetails?.country} className="mb-2" />
            <KeyValue title="Shipping State" value={requestSampleDetails?.state} className="mb-2" />
            <KeyValue title="Full Address" value={requestSampleDetails?.address} lineClamp={false} className="mb-2" />
            <KeyValue title="Phone Number" value={requestSampleDetails?.phone} lineClamp={false} className="mb-2" />
          </Grid>
        </Grid>

        {requestSampleDetails?.description && (
          <Box className="mt-4">
            <Typography variant="subtitle2" className="mb-3 text-darkGray">
              Additional Information
            </Typography>

            <Typography variant="body2" className="text-midLightGray">
              {requestSampleDetails?.description}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
