import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { Alert, Box, Drawer, useMediaQuery } from '@mui/material';
import { useGetSampleDrawer } from './useGetSampleDrawer';
import { Button, Chip, Typography } from 'design-system';
import { ColorsBar } from '../attributes/colors-bar';
import { SizeBar } from '../attributes/size-bar';
import { TextBar } from '../attributes/text-bar';
import styles from './styles.module.scss';
import { getUnit } from 'utils';

export const RequestSampleDrawer = ({
  getSampleDrawerOpen,
  toggleGetSampleDrawer,
  selectedCurrentPictureIndex,
  selectedActiveColorAttrValue,
  selectedActiveSizeAttrValue,
  selectedActiveThicknessAttrValue,
  selectedActiveFinishAttrValue,
}) => {
  const {
    productDetails,
    callAttributeChange,
    productAttributeChange,
    onNextGetSample,
    colorsAttribute,
    sizeAttribute,
    finishAttribute,
    thicknessAttribute,
    activeColorAttrValue,
    activeSizeAttrValue,
    activeThicknessAttrValue,
    activeFinishAttrValue,
    getCurrentPictureUrl,
    onClose,
  } = useGetSampleDrawer({
    selectedActiveColorAttrValue,
    selectedActiveSizeAttrValue,
    selectedActiveThicknessAttrValue,
    selectedActiveFinishAttrValue,
    selectedCurrentPictureIndex,
    toggleGetSampleDrawer,
  });

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  return (
    <Drawer
      open={getSampleDrawerOpen}
      onClose={() => toggleGetSampleDrawer(false)}
      anchor={isTouchDevice ? 'bottom' : 'right'}
      keepMounted
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isTouchDevice ? '100%' : '35%',
          height: isTouchDevice ? '60%' : '100%',
        },
      }}
    >
      <Box className={styles.drawer__container}>
        {/* Drawer Header */}
        <Box className={styles.drawer__header}>
          <Typography variant="subtitle1" className=" text-darkGray">
            Request a sample
          </Typography>
          <CloseIcon
            className="fill-midLightGray cursor-pointer"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
          />
        </Box>

        <Box className={styles.content__container} id="gs-drawer-container">
          {/* Product Details */}
          <Typography variant="h6" className="text-midDarkGray mb-3">
            {productDetails?.productDetailsModel.name}
          </Typography>

          {/* Image */}
          <Box className={styles.gs__image__container}>
            <img src={getCurrentPictureUrl('url') || ''} alt={getCurrentPictureUrl('alt') || ''} />
            <Typography variant="caption">x1</Typography>
          </Box>

          <Chip
            label="Quantity : 1"
            variant="outlined"
            className="mt-3 text-midLightGray"
            sx={{ flexShrink: 0, height: '32px', width: 'max-content' }}
          />

          {/* Price */}
          <Box className="py-3">
            <Typography variant="subtitle1" className="text-midDarkGray">
              Price:{' '}
              {productAttributeChange?.price
                ? productAttributeChange?.price
                : productDetails?.productDetailsModel?.productPrice.price}{' '}
              <Typography variant="body2" className="text-midLightGray" id="Unit" component="span">
                {getUnit(productDetails?.productDetailsModel!)}
              </Typography>
            </Typography>
          </Box>

          {/* Warning */}
          <Box
            sx={{
              backgroundColor: 'rgba(255, 244, 234, 1)',
              width: '100%',
              color: 'var(--deepYellow)',
              padding: '12px',
              marginBottom: '16px',
              borderRadius: '4px !important',
            }}
          >
            <Alert
              icon={false}
              sx={{
                backgroundColor: 'unset',
                color: 'unset',
                padding: 'unset !important',
              }}
            >
              <Typography variant="body2">
                This price is for bulk orders, the final price will be sent in a quotation based on shipping fees.
              </Typography>
            </Alert>
          </Box>

          {/* Variations */}
          {colorsAttribute && (
            <ColorsBar
              className="mb-2"
              attr={colorsAttribute}
              onAttributeChange={callAttributeChange}
              activeValue={activeColorAttrValue}
            />
          )}
          {sizeAttribute && (
            <SizeBar
              className="mb-2"
              attr={sizeAttribute}
              onAttributeChange={callAttributeChange}
              activeValue={activeSizeAttrValue}
            />
          )}
          {thicknessAttribute && (
            <TextBar
              className="mb-2"
              attr={thicknessAttribute}
              onAttributeChange={callAttributeChange}
              activeValue={activeThicknessAttrValue}
            />
          )}
          {finishAttribute && (
            <TextBar
              className="mb-2"
              attr={finishAttribute}
              onAttributeChange={callAttributeChange}
              activeValue={activeFinishAttrValue}
            />
          )}
        </Box>

        {/* Fixed Bottom Section */}
        <Box className={styles.drawer__footer}>
          <Button
            label="Next"
            variant="contained"
            color="magentaPink"
            size="large"
            className="w-full"
            onClick={() => onNextGetSample()}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
