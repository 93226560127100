import { QuotationsResponse } from 'services/dtos/quotation/quotations.response';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { VendorQuotationCard } from '../vendor-quotation-card';
import { RFQ } from 'services/types/generic/rfq-entity';
import { AcceptModal } from '../modals/accept-modal';
import { RejectModal } from '../modals/reject-modal';
import { useMyQuotation } from './useMyQuotation';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box, Grid } from '@mui/material';
import classNames from 'classnames';

type Props = {
  quotations: QuotationsResponse;
  rfqDetails: RFQ;
};

export const Quotations = ({ rfqDetails, quotations }: Props) => {
  const {
    vendorsQuotations,
    acceptModalOpen,
    setAcceptModalOpen,
    rejectModalOpen,
    handleSecondaryClickQuotation,
    onAccept,
    onReject,
    onNegotiate,
    handleAcceptQuotation,
    handleRejectQuotation,
    loading,
    control,
    isValid,
    newQuotationsCount,
    isQuotationExpanded,
    setIsQuotationExpanded,
    selectedQuotation,
  } = useMyQuotation({ quotations, rfqDetails });

  return (
    <>
      <AcceptModal
        open={acceptModalOpen}
        setOpen={setAcceptModalOpen}
        handlePrimaryClick={handleAcceptQuotation}
        loading={loading}
      />

      <RejectModal
        control={control}
        isValid={isValid}
        open={rejectModalOpen}
        handlePrimaryClick={handleRejectQuotation}
        handleSecondaryClick={handleSecondaryClickQuotation}
        loading={loading}
      />

      {vendorsQuotations && vendorsQuotations.length > 0 && (
        <Box
          className={classNames(styles.vendor__quotation__container, 'responsive-full-width-ignore-container-padding')}
        >
          <Box className="flex items-center mb-6">
            <Typography variant="h4" component="h4">
              Response:{' '}
            </Typography>

            <Box className="flex items-center justify-center w-6 h-6 ms-2 rounded-full text-xs bg-[#3bb87d] text-white">
              {newQuotationsCount}
            </Box>

            <button onClick={() => setIsQuotationExpanded(!isQuotationExpanded)} className="ms-1 cursor-pointer">
              {isQuotationExpanded && <KeyboardArrowDown style={{ color: 'var(--midLightGray)' }} />}
              {!isQuotationExpanded && <KeyboardArrowUp style={{ color: 'var(--midLightGray)' }} />}
            </button>
          </Box>

          {isQuotationExpanded && (
            <Grid container spacing={{ xs: 1.5, md: 3 }}>
              {vendorsQuotations.map(vendorQuotations => (
                <Grid item xs={12} sm={7.5} lg={4} key={`vendor-quotations-${vendorQuotations[0].createdBy.email}`}>
                  <VendorQuotationCard
                    rfqDetails={rfqDetails}
                    vendorQuotations={vendorQuotations}
                    onAccept={onAccept}
                    onNegotiate={onNegotiate}
                    onReject={onReject}
                    showLoading={loading && selectedQuotation?.quotationId === vendorQuotations[0].quotationId}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};
