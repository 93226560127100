import { Button, Listing, Loader, Pagination, StatusChip, Typography } from 'design-system';
import { SamplesTableToolbar } from '../components/samples-table-toolbar';
import { Box, Card, Divider, TableCell, TableRow } from '@mui/material';
import { Sample } from 'services/types/generic/sample-entity';
import { useSampleList } from './useRequestSampleList';
import styles from './styles.module.scss';
import { HasNoResult } from 'components';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

export const RequestSampleList = () => {
  const {
    // Data Related
    mySamples,
    isSamplesFetching,

    // UI Related
    headerColumns,
    selectColumns,
    showTableData,
    showPagination,
    showHasNoTableData,
    showHasNoSearchResult,
    tableContainerHeight,
    isTouchDevice,
    handleViewDetails,

    // Filter & Pagination Related
    count,
    pageNumber,
    onChangePage,
    setSearchQuery,
    orderBy,
    setOrderBy,
  } = useSampleList();

  const renderIndexColumn = ({ sampleItem, select }: { sampleItem: Sample; select: string }) => {
    return (
      <Box className={styles.index__col__container}>
        <Box className="file__container" sx={{ flexShrink: 0 }}>
          <img src={sampleItem[select]} alt="" />
        </Box>

        <Box className={styles.product__name}>
          <Typography variant="caption" className="text-darkGray font-bold truncate text-start max-w-[200px]">
            {sampleItem.productName}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderOtherCells = ({ sampleItem, select }: { sampleItem: Sample; select: string }) => {
    if (select === 'status')
      return (
        <Box className="flex justify-center">
          <StatusChip
            status={sampleItem.status}
            sx={{
              width: '83px',
              height: '28px',
            }}
          />
        </Box>
      );

    return (
      <Typography variant="body2" className="text-midLightGray truncate">
        {sampleItem[select]}
      </Typography>
    );
  };

  const HeaderRow = () => {
    return (
      <>
        {headerColumns.map((column, idx) => (
          <TableCell
            key={`products-listing-header-cell-${idx}`}
            sx={{ color: showHasNoTableData ? 'var(--gray)' : '' }}
          >
            {column}
          </TableCell>
        ))}
      </>
    );
  };

  const TableBody = () => {
    return (
      <>
        {mySamples &&
          mySamples.data.map((sampleItem, idx) => (
            <TableRow key={`products-table-row-${idx}`}>
              {selectColumns.map((select, idx) => (
                <TableCell key={`products-table-cell-${idx}`} sx={{ maxWidth: '200px' }}>
                  {idx === 0 && renderIndexColumn({ sampleItem, select })}
                  {idx !== 0 && renderOtherCells({ sampleItem, select })}
                </TableCell>
              ))}

              <TableCell>
                <Button
                  size="small"
                  variant="outlined"
                  color="violet"
                  onClick={() => handleViewDetails(sampleItem.id)}
                  className="w-[92px]"
                  label="View"
                />
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  const ResponsiveListItem = ({ sampleItem }: { sampleItem: any }) => {
    return (
      <Card
        className={classNames(styles.sample__card, 'responsive-full-width-ignore-container-padding')}
        onClick={() => handleViewDetails(sampleItem.id)}
      >
        <Box className="flex mb-1 items-center">
          <Box className="flex items-center justify-center me-3">
            <Box className="file__container">
              <img src={sampleItem.productImage} alt="" />
            </Box>
          </Box>
          <Typography variant="subtitle2" className="text-darkGray">
            {sampleItem.productName}
          </Typography>
        </Box>

        <Divider className="my-4" />

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Status
          </Typography>
          <StatusChip
            status={sampleItem?.status}
            sx={{
              width: { xs: '74px', md: '94px' },
              height: { xs: '28px', md: '34px' },
            }}
          />
        </Box>

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Creation Date
          </Typography>
          <Typography variant="body2" className="text-darkGray">
            {moment(sampleItem.CreatedAt).utc().format('MMMM DD, YYYY')}
          </Typography>
        </Box>

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Vendor Name
          </Typography>
          <Typography variant="body2" className="text-darkGray">
            {sampleItem.vendorName}
          </Typography>
        </Box>
      </Card>
    );
  };

  return (
    <>
      {/* Web */}
      {!isTouchDevice && (
        <Listing
          tableHeader={<HeaderRow />}
          tableBody={<TableBody />}
          tableToolbar={
            <SamplesTableToolbar
              setSearchQuery={setSearchQuery}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              disabled={showHasNoTableData}
            />
          }
          showPagination={showPagination}
          tablePagination={
            <>{mySamples && <Pagination count={count} page={pageNumber} handleChangePage={onChangePage} />}</>
          }
          tableContainerProps={{ sx: { height: tableContainerHeight } }}
          loadingData={isSamplesFetching}
          showTableData={showTableData}
          showHasNoTableData={showHasNoTableData}
          hasNoTableData={
            <HasNoResult title="Ready to take the next step?" description="Submit a request now from the product page" />
          }
          hasNoSearchResults={
            <HasNoResult title="It seems like your search is not available right now!" description="" />
          }
          showHasNoSearchResults={showHasNoSearchResult}
        />
      )}

      {isTouchDevice && (
        <>
          <SamplesTableToolbar
            setSearchQuery={setSearchQuery}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            disabled={showHasNoTableData}
          />

          {isSamplesFetching && <Loader />}

          {!isSamplesFetching && (
            <>
              {mySamples && (mySamples.count ?? 0) > 0 && (
                <Box className="px-5">
                  {mySamples &&
                    mySamples.data.map(sampleItem => (
                      <ResponsiveListItem key={`responsive-list-sampleItem${sampleItem.id}`} sampleItem={sampleItem} />
                    ))}
                </Box>
              )}

              {showPagination && mySamples && (mySamples.count ?? 0) > 0 && (
                <Box className="my-10">
                  <Pagination count={count} page={pageNumber} handleChangePage={onChangePage} />
                </Box>
              )}
            </>
          )}

          {showHasNoTableData && (
            <HasNoResult
              title="Ready to take the next step?"
              description="Submit an sample request now !"
              buttonComponent={
                <Link to="" id="CreateSampleLink">
                  <Button size="small" className="w-36" label="Create Sample" />
                </Link>
              }
            />
          )}

          {showHasNoSearchResult && (
            <HasNoResult title="It seems like your search is not available right now!" description="" />
          )}
        </>
      )}
    </>
  );
};
