import { AppContainer, ProductCardSkeleton, TypographySkeleton } from 'design-system';
import { Box, Grid, Skeleton } from '@mui/material';
import styles from './styles.module.scss';

export const BrowseByCategorySkeleton = () => {
  return (
    <>
      <Box className="flex flex-col gap-7">
        <Box className={styles.browse__by__category__header}>
          <AppContainer>
            <TypographySkeleton variant="h3" component="h3" className="w-1/3 mb-2 max-sm:mb-4" />

            <Grid
              container
              spacing={{ xs: 1.5, md: 3 }}
              wrap="nowrap"
              sx={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
            >
              {Array(10)
                .fill(null)
                .map((_, idx) => (
                  <Grid item width={'125px'} key={`browse-by-category-skeleton-item-${idx}`}>
                    <Skeleton variant="rectangular" animation="wave" height={'40px'} />
                  </Grid>
                ))}
            </Grid>
          </AppContainer>
        </Box>

        <AppContainer>
          <Grid container spacing={{ xs: 1.5, md: 3 }}>
            {Array(25)
              .fill(null)
              .map((_, idx) => (
                <Grid item xs={6} sm={4} md={2.4} key={`browse-by-category-product-skeleton-item-${idx}`}>
                  <ProductCardSkeleton />
                </Grid>
              ))}
          </Grid>
        </AppContainer>
      </Box>
    </>
  );
};
