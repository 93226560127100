import _ from 'lodash';

export const toListItems = (obj: Record<string, string>) => {
  const listItems: { label: string; value: string }[] = [];
  for (const key in obj) {
    const label = key;
    const value: string = obj[key];
    listItems.push({ label, value });
  }
  return _.sortBy(listItems, ['label']);
};
