import { ReactComponent as WantToGetQuotationIcon } from '../../../../assets/images/want-to-get-quotation.svg';
import { Typography, ControlledAutocompleteSelect, LoadingButton, ControlledTextField } from 'design-system';
import { useSubmitRfqFirstStep } from 'pages/rfq/steps/first-step/useSubmitRfqFirstStep';
import { UnitEnum } from 'services/types/generic/enums';
import { Box, Grid, Hidden } from '@mui/material';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const CallToAction = () => {
  const { rfqIndustryItems, onSubmit, control, userEmail, toListItems, loading, isValid, isDownMd } =
    useSubmitRfqFirstStep();

  return (
    <Box className={styles.call__to__action__container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6.5} className="z-10">
          <Box className={classNames(styles.rfq__content, 'responsive-full-width-ignore-container-padding')}>
            <Box className={styles.homepage__rfq__header}>
              <WantToGetQuotationIcon className={isDownMd ? 'w-6 h-6' : 'w-10 h-10'} />
              <Typography variant="h3" className="text-white">
                Submit your request in no time!
              </Typography>
            </Box>

            <Hidden mdDown>
              <Typography variant="subtitle2" className="text-lightGray max-w-[579px] text-left">
                Can't find what you are looking for? Just a few steps to find your product.
              </Typography>
            </Hidden>
          </Box>
        </Grid>

        <Grid item xs={12} md={5.5}>
          <Grid container spacing={3} className={styles.homepage__rfq__fields}>
            <Grid item xs={12} md={12}>
              <ControlledAutocompleteSelect
                name="industryId"
                control={control}
                options={rfqIndustryItems}
                placeholder="Select Product Industry"
                inputLabelProps={{ required: true, children: 'Product Industry', style: { color: 'var(--white)' } }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <ControlledTextField
                name="productName"
                control={control}
                inputLabelProps={{ required: true, children: 'Product Name', style: { color: 'var(--white)' } }}
                textFieldProps={{
                  placeholder: 'Enter or specify product name',
                  InputProps: { inputProps: { inputProps: { maxLength: 40 } } },
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                  <ControlledTextField
                    name="quantity"
                    control={control}
                    inputLabelProps={{ required: true, children: 'Quantity', style: { color: 'var(--white)' } }}
                    textFieldProps={{
                      placeholder: 'Enter the quantity',
                      type: 'number',
                      InputProps: {
                        inputProps: {
                          min: 1,
                          pattern: '\\d*',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ControlledAutocompleteSelect
                    name="unit"
                    control={control}
                    options={toListItems(UnitEnum)}
                    inputLabelProps={{ required: true, children: 'Select unit', style: { color: 'var(--white)' } }}
                    placeholder="Select unit"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <ControlledTextField
                name="email"
                control={control}
                inputLabelProps={{
                  required: !userEmail,
                  children: 'Email',
                  style: { color: 'var(--white)' },
                  disabled: !!userEmail,
                }}
                textFieldProps={{ placeholder: 'Enter your email', disabled: !!userEmail }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <LoadingButton
                size="large"
                variant="contained"
                color="magentaPink"
                className="w-full"
                onClick={onSubmit}
                disabled={!isValid}
                label="Next"
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
