import { Slider, TypographySkeleton } from 'design-system';
import { Box } from '@mui/material';

export const NopSliderSkeleton = ({ ...props }: any) => {
  return (
    <>
      <Box sx={{ px: 1 }}>
        <Slider disabled {...props} />
      </Box>

      <Box className="flex justify-between">
        <TypographySkeleton variant="subtitle1" className="w-1/3" />
        <TypographySkeleton variant="subtitle1" className="w-1/3" />
      </Box>
    </>
  );
};
