import { Autocomplete as MuiAutocomplete, Popper, InputLabelProps } from '@mui/material';
import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import { ErrorFeedback } from '../../../typographies/error-feedback';
import { InputLabel } from '../../../typographies/input-label';
import { TextField } from '../../core/text-field';
import { useState } from 'react';

interface RHFAutocompleteFieldProps<O extends { value: string; label: string }, TField extends FieldValues> {
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  placeholder?: string;
  disabled?: boolean;
  inputLabelProps?: InputLabelProps;
}

export const ControlledAutocompleteSelect = <O extends { value: string; label: string }, TField extends FieldValues>(
  props: RHFAutocompleteFieldProps<O, TField>,
) => {
  const { control, options, name, disabled, placeholder, inputLabelProps } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;

        return (
          <>
            {inputLabelProps?.children && <InputLabel {...inputLabelProps} />}

            <MuiAutocomplete
              PopperComponent={({ style, ...popperProps }) => <Popper {...popperProps} style={{ ...style }} />}
              open={open}
              options={options}
              value={value ? options.find(option => option.value === value) || null : null}
              getOptionLabel={option => option.label}
              renderInput={fieldParams => (
                <TextField
                  {...fieldParams}
                  name={`${name}-${Math.random()}`}
                  placeholder={placeholder}
                  inputRef={ref}
                  error={!!fieldState.error}
                  InputProps={{ ...fieldParams.InputProps }}
                />
              )}
              onChange={(event, newValue) => {
                onChange(newValue ? newValue.value : null);
              }}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disabled={disabled}
            />

            {fieldState.error && <ErrorFeedback message={fieldState.error.message} />}
          </>
        );
      }}
    />
  );
};
