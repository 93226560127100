import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as MessengerSelectedIcon } from 'design-system/public/icons/messenger-selected.svg';
import { ReactComponent as RFQSelectedIcon } from 'design-system/public/icons/rfq-selected.svg';
import { ReactComponent as MessengerIcon } from 'design-system/public/icons/messenger.svg';
import { ReactComponent as CategoryIcon } from 'design-system/public/icons/apps.svg';
import { ReactComponent as HomeIcon } from 'design-system/public/icons/home.svg';
import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { useCustomAuth } from 'hooks';

export const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useCustomAuth();
  const { isChatPopupOpen, unreadMessages } = useMessageCenterContext();
  const [notificationMode, setNotificationMode] = useState<boolean>(false);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const bottomNavigationLinks = [
    { label: 'Home', value: '/home' },
    { label: 'All Categories', value: '/all-categories-list' },
    { label: 'RFQ', value: '/rfq/submit/first-step' },
  ];

  if (isAuthenticated) bottomNavigationLinks.push({ label: 'Messenger', value: '/message-center' });

  useEffect(() => {
    if (unreadMessages > 0) setNotificationMode(true);
    else setNotificationMode(false);
  }, [unreadMessages]);

  const hasUnreadBg = !isChatPopupOpen && notificationMode;

  const ChatNotificationBadgeCount = () => {
    return (
      <>
        {hasUnreadBg && (
          <Box className={styles.notification__badge}>
            <Typography variant="body2" className="text-xs">
              {unreadMessages}
            </Typography>
          </Box>
        )}
      </>
    );
  };
  const modifiedRfqPathName = () => {
    if (location.pathname.includes('rfq/submit')) return '/rfq/submit/first-step';
  };

  const getIcon = (loc: string) => {
    switch (loc) {
      case '/home':
        if (location.pathname === loc)
          return <HomeIcon stroke="var(--darkGray)" fill="var(--darkGray)" className={styles.bottom__nav__icon} />;
        else return <HomeIcon stroke="var(--midDarkGray)" className={styles.bottom__nav__icon} />;

      case '/all-categories-list':
        if (location.pathname === loc)
          return <CategoryIcon stroke="var(--darkGray)" fill="var(--darkGray)" className={styles.bottom__nav__icon} />;
        else return <CategoryIcon stroke="var(--midDarkGray)" className={styles.bottom__nav__icon} />;

      case '/message-center':
        if (location.pathname === loc)
          return (
            <>
              <MessengerSelectedIcon fill="var(--darkGray)" className={styles.bottom__nav__icon} />
              <ChatNotificationBadgeCount />
            </>
          );
        else
          return (
            <>
              <MessengerIcon stroke="var(--midDarkGray)" className={styles.bottom__nav__icon} />
              <ChatNotificationBadgeCount />
            </>
          );

      case '/rfq/submit/first-step':
        const pathName = modifiedRfqPathName();
        if (pathName === loc) return <RFQSelectedIcon className={styles.bottom__nav__icon} />;
        else return <RFQIcon stroke="var(--midDarkGray)" className={styles.bottom__nav__icon} />;
    }
  };

  return (
    <>
      {isTouchDevice && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
        >
          <Divider sx={{ marginX: '20px', borderColor: 'var(--lightGray)' }} />

          <MuiBottomNavigation showLabels value={location.pathname}>
            {bottomNavigationLinks.map((bNavLink, idx) => (
              <BottomNavigationAction
                key={`${bNavLink.label}#${idx}`}
                label={bNavLink.label}
                value={bNavLink.value}
                icon={getIcon(bNavLink.value)}
                onClick={() => navigate(bNavLink.value)}
              />
            ))}
          </MuiBottomNavigation>
        </Box>
      )}
    </>
  );
};
