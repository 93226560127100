import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { Box, Drawer, Grid, useMediaQuery } from '@mui/material';
import { Button, IconButton, Typography } from 'design-system';
import { NopCheckboxFilter } from '../nop-checkbox-filter';
import styles from './styles.module.scss';
import { NopSlider } from 'components';
import { useState } from 'react';

type Props = {
  itemsCount: number | undefined;
  pageSizeComponent: JSX.Element;
  sortComponent: JSX.Element;
  query?: string;
  manufacturerOptions?: any;
  onChangeManufacturer?: any;
  onFilter?: Function;
  categoryProductsResponse: any;
  priceRange: number[];
  setPriceRange: Function;
};

const FilterDrawer = ({
  manufacturerOptions,
  onChangeManufacturer,
  onFilter,
  filterDrawerOpen,
  setFilterDrawerOpen,
  categoryProductsResponse,
  priceRange,
  setPriceRange,
}) => {
  return (
    <Drawer
      anchor="right"
      open={filterDrawerOpen}
      onClose={() => setFilterDrawerOpen(false)}
      PaperProps={{ style: { width: '70%' } }}
    >
      <Box className="p-5">
        <Box className="flex items-center justify-between mb-3">
          <Typography variant="h5" component="h5" className="text-darkGray">
            Filter Items
          </Typography>

          <CloseIcon className="w-6 h-6 fill-midLightGray" onClick={() => setFilterDrawerOpen(false)} />
        </Box>

        <Typography variant="h6" component="h6" className="text-darkGray section-subtitle mb-8">
          Price Range
        </Typography>

        <NopSlider
          min={0}
          max={categoryProductsResponse?.catalogProductsModel?.priceRangeFilter?.availablePriceRange.to || 10000}
          value={priceRange}
          onChange={setPriceRange}
        />

        {manufacturerOptions && onChangeManufacturer && (
          <NopCheckboxFilter manufacturerOptions={manufacturerOptions} onChangeManufacturer={onChangeManufacturer} />
        )}
      </Box>

      <Box className="fixed bottom-0 px-5 py-4 w-[70%]">
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            {onFilter && (
              <Button
                size="small"
                className="mt-3 w-full hover:bg-brand2"
                startIcon={<FilterIcon stroke="var(--white)" />}
                onClick={onFilter}
                label="Filter"
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export const NopResultsHeader = ({
  itemsCount,
  pageSizeComponent,
  sortComponent,
  query,
  manufacturerOptions,
  onChangeManufacturer,
  onFilter,
  categoryProductsResponse,
  priceRange,
  setPriceRange,
}: Props) => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const onFilterClick = () => {
    onFilter && onFilter();
    setFilterDrawerOpen(false);
  };

  return (
    <Box className={styles.results__header}>
      <Typography variant="subtitle1" className="text-darkGray">
        {query ? query : 'Results'}&nbsp;&nbsp;
        <Typography variant="subtitle1" className="text-deepYellow" component={'span'}>
          {itemsCount}
        </Typography>
      </Typography>

      <Box className="flex">
        <Box className="me-6 max-sm:me-3">{pageSizeComponent}</Box>
        <Box className="max-sm:me-3">{sortComponent}</Box>
        {isTouchDevice && (
          <IconButton
            icon={<FilterIcon stroke="var(--midLightGray)" />}
            size="small"
            onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
          />
        )}

        <FilterDrawer
          filterDrawerOpen={filterDrawerOpen}
          setFilterDrawerOpen={setFilterDrawerOpen}
          manufacturerOptions={manufacturerOptions}
          onChangeManufacturer={onChangeManufacturer}
          onFilter={onFilterClick}
          categoryProductsResponse={categoryProductsResponse}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
      </Box>
    </Box>
  );
};
