import { ResponsiveSecondaryHeader } from '../responsive-secondary-header';
import { ImmediatePurchaseHeader } from '../immediate-purchase-header';
import { OtherSecondaryHeader } from '../other-secondary-header';
import { ResponsiveMainHeader } from '../responsive-main-header';
import { HomeSecondaryHeader } from '../home-secondary-header';
import { useMediaQuery, AppBar, Divider } from '@mui/material';
import { BreadcrumbHeader, CustomToolbar } from 'components';
import { OtherMainHeader } from '../other-main-header';
import { HomeMainHeader } from '../home-main-header';
import { VerifiedHeader } from '../verified-header';
import { useLocation } from 'react-router-dom';
import { EventHeader } from '../event-header';
import { AppContainer } from 'design-system';

export const HeaderHandler = () => {
  const { pathname } = useLocation();
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  return (
    <>
      <AppBar
        position="sticky"
        elevation={isTouchDevice ? 1 : 0}
        sx={{ backgroundColor: isTouchDevice ? 'white' : 'var(--background)', borderRadius: 0 }}
      >
        {!isTouchDevice && (
          <>
            {pathname === '/home' && (
              <CustomToolbar disableGutters className="flex flex-col pt-0">
                <ImmediatePurchaseHeader />
                <EventHeader />
                <VerifiedHeader />
                <HomeMainHeader />
                <HomeSecondaryHeader />
              </CustomToolbar>
            )}

            {pathname !== '/home' && (
              <>
                <CustomToolbar disableGutters className="flex flex-col py-0 bg-white">
                  <OtherMainHeader />
                  <Divider sx={{ borderColor: 'var(--antiFlashWhite)', width: '100%' }} />

                  <OtherSecondaryHeader />
                </CustomToolbar>
                <Divider sx={{ borderColor: 'var(--antiFlashWhite)', width: '100%' }} />
              </>
            )}
          </>
        )}

        {isTouchDevice && (
          <>
            <CustomToolbar disableGutters className="flex flex-col">
              <AppContainer>
                <ResponsiveMainHeader />

                <ResponsiveSecondaryHeader />
              </AppContainer>
            </CustomToolbar>

            {pathname === '/home' && <ImmediatePurchaseHeader />}
            <EventHeader />
            <VerifiedHeader />
          </>
        )}
      </AppBar>
      <BreadcrumbHeader />
    </>
  );
};
