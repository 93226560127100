import { SearchField, Sort } from 'design-system';
import styles from './styles.module.scss';
import { Box } from '@mui/material';
import classNames from 'classnames';

type HeaderProps = {
  loading?: boolean;
  handleExportClick?: any;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setOrderBy: any;
  orderBy: any;
  disabled: boolean;
};

export const RfqTableToolbar = ({ setSearchQuery, orderBy, setOrderBy, disabled }: HeaderProps) => {
  return (
    <Box className={classNames(styles.header__container, 'responsive-full-width-ignore-container-padding')}>
      <Box className="flex w-full items-center justify-between">
        <SearchField
          onInputChange={e => {
            if (e !== undefined) {
              return setSearchQuery(e);
            }
          }}
          placeholder="Search in Rfq list"
          className="w-[280px]"
          disabled={disabled}
        />
        <Sort availableSortOptions={['Newest', 'Oldest']} value={orderBy} setOrderBy={setOrderBy} disabled={disabled} />
      </Box>
    </Box>
  );
};
