import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { getCategoryTree } from 'services/orchestrator.service';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useCategoryTree = () => {
  const [currentCategory, setCurrentCategory] = useState<CategorySimpleModelDto>();
  const [subCategories, setSubCategories] = useState<CategorySimpleModelDto[] | null>(null);

  const { data: categoryTree, isLoading } = useQuery<CategorySimpleModelDto[]>({
    queryKey: ['categoryTree'],
    queryFn: async () => {
      const res = await getCategoryTree();
      return res?.data?.filter(category => category.numberOfProducts !== 0);
    },
  });

  // Update subCategories whenever categoryTree change
  useEffect(() => {
    if (categoryTree && categoryTree.length > 0) {
      setSubCategories(categoryTree[0]?.subCategories || null);
      setCurrentCategory(categoryTree[0]);
    }
  }, [categoryTree]);

  return {
    categoryTree,
    subCategories,
    setSubCategories,
    currentCategory,
    setCurrentCategory,
    isLoading,
  };
};
