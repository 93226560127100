import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

export const useCategoryMenu = ({ setCurrentCategory, setSubCategories }) => {
  const [isMenuShown, setIsMenuShown] = useState<Boolean>(false);
  const navigate = useNavigate();
  const menuRef = useRef<any>(null);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuShown(false);
    }
  };

  const onHoverListItem = (category: CategorySimpleModelDto) => {
    category.subCategories ? setSubCategories(category.subCategories) : setSubCategories(null);
    setCurrentCategory(category);
  };
  return {
    menuRef,
    isTouchDevice,
    navigate,
    isMenuShown,
    setIsMenuShown,
    onHoverListItem,
  };
};
