import { ProductDetailsModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { emailPattern, getCountryCodeByName, phoneValidation } from 'utils';
import { submitRequestSample } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCustomAuth, useMyUser } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { State } from 'country-state-city';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import * as Yup from 'yup';

export const useRequestSample = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { user, getAccessTokenSilently } = useCustomAuth();
  const { myUser } = useMyUser();
  const navigate = useNavigate();

  const [states, setStates] = useState<any[]>([]);
  const [userEmail, setUserEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const formSchema = Yup.object().shape({
    shipmentTerm: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    adrs: Yup.string().trim().required('This field is required'),
    email: Yup.string().required('Email is required').matches(emailPattern, 'Enter a valid email address'),
    phone: phoneValidation('Invalid phone number'),
    description: Yup.string(),
  });

  const {
    handleSubmit,
    formState: { isValid },
    getValues,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async () => {
    setLoading(true);
    const data = JSON.parse(localStorage.getItem('request-sample-data') || '{}');
    const values = getValues();
    const bodyRest = {
      shipmentTerm: values.shipmentTerm,
      country: values.country,
      state: values.state,
      address: values.adrs,
      email: values.email,
      phone: values.phone,
      description: values.description,
    };
    const body = {
      ...data,
      ...bodyRest,
    };
    const token = await getAccessTokenSilently();

    try {
      await submitRequestSample({ body, token });
      navigate('/request-sample/submit-success', { replace: true });
      localStorage.removeItem('request-sample-data');
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.message) toast.error('Un-expected Error.');
      else toast.error('Un-expected Error.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('request-sample-data') || '{}');
    const details: ProductDetailsModelDto = JSON.parse(data.productDetails);

    updateBreadcrumbs([
      { label: 'Product details', link: `product-details/${details.id}/${details.seName}}` },
      { label: 'Shipping information', link: '/request-sample/personal-shipping-info' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      setUserEmail(user?.email!);
      setValue('email', user?.email!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch(form => {
      const countryCode = getCountryCodeByName(form.country);
      setStates(
        State.getStatesOfCountry(countryCode).map(item => {
          return { label: item.name, value: item.name };
        }),
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    myUser?.user_metadata?.phone && setValue('phone', myUser?.user_metadata?.phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]);

  return {
    isValid,
    control,
    handleSubmit,
    onSubmit,
    states,
    getValues,
    userEmail,
    loading,
  };
};
