import { ReactComponent as ImmediatePurchase } from 'design-system/public/icons/immediate-purchase.svg';
import { ReactComponent as ArrowRight } from 'design-system/public/icons/arrow-right.svg';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { AppContainer, Typography } from 'design-system';
import { Box, useMediaQuery } from '@mui/material';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export const ImmediatePurchaseHeader = () => {
  const immediatePurchaseEnabled = useFeatureFlagEnabled('immediate-purchase');

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  if (!immediatePurchaseEnabled) return <></>;
  return (
    <Box className={styles.immediate_purchase_header}>
      <Link to={'/immediate-purchase'}>
        <AppContainer>
          <Box className="flex h-10 items-center justify-center">
            <Box className="flex items-center">
              <ImmediatePurchase className="me-2" />
              <Typography variant={isTouchDevice ? 'subtitle2' : 'subtitle1'}>
                Save up to 30% on immediate purchase products!
              </Typography>
              <ArrowRight className="ms-2" fill="var(--white)" />
            </Box>
          </Box>
        </AppContainer>
      </Link>
    </Box>
  );
};
