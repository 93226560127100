import { StepThreeCreateRfqDto } from 'services/dtos/rfq/StepThreeCreateRfq.dto';
import { updateThirdStepRfq } from 'services/orchestrator.service';
import { getCountryCodeByName, sendEventToGTM } from 'utils';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { State } from 'country-state-city';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRfqByToken } from 'hooks';
import * as Yup from 'yup';

export const useSubmitRfqThirdStep = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = pathname.split('/').pop();

  const { updateBreadcrumbs } = useBreadcrumbs();
  const [states, setStates] = useState<any[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  useRfqByToken({ token, setPageLoading });

  const paymentTerms = [
    { value: 'Pay on Delivery', label: 'Pay on Delivery' },
    { value: 'Letter of Credit', label: 'Letter of Credit' },
    { value: 'Bank Transfer', label: 'Bank Transfer' },
    { value: 'Pay Online', label: 'Pay Online' },
    { value: 'Others', label: 'Others' },
  ];

  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');

  const formSchema = Yup.object().shape({
    shipmentTerm: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    adrs: Yup.string().trim().required('This field is required'),
    paymentTerms: Yup.string().trim().required('This field is required'),
    targetUnitPrice: Yup.number()
      .min(1, 'Quantity must be at least 1')
      .typeError('This field is required')
      .required('This field is required'),
  });
  const {
    formState: { isValid },
    getValues,
    watch,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  // Mutation for updating the RFQ
  const { mutateAsync: updateRfqThirdStepMutation, isPending: isPendingRfqUpdate } = useMutation({
    mutationKey: ['updateRfqThirdStep', token],
    mutationFn: async ({ body, token }: { body: StepThreeCreateRfqDto; token: string }) => {
      const { data } = await updateThirdStepRfq({ body, token });
      return data;
    },
    onSuccess: data => {
      sendEventToGTM('user_submit_rfq', { rfq_short_id: data.shortID });
      navigate('/rfq/submit-success', { replace: true });
    },
    onError: error => {
      toast.error(error.message);
    },
  });

  const onSubmit = async () => {
    const values = getValues();

    const body = {
      shipmentTerm: values.shipmentTerm,
      country: values.country,
      state: values.state,
      address: values.adrs,
      targetUnitPrice: values.targetUnitPrice,
      paymentTerms: values.paymentTerms,
    };
    if (token) await updateRfqThirdStepMutation({ body, token });
  };

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Submission', link: '/rfq/submit/third-step' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch(form => {
      const countryCode = getCountryCodeByName(form.country);
      setStates(
        State.getStatesOfCountry(countryCode).map(item => {
          return { label: item.name, value: item.name };
        }),
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (const element of elements) {
      //@ts-ignore
      element.autocomplete = 'new-password';
    }
  }, []);

  return {
    onSubmit,
    control,
    states,
    isValid,
    getValues,
    pageLoading,
    isPendingRfqUpdate,
    paymentTerms,
    isTouchDevice,
  };
};
