import { PaletteColorOptions, createTheme } from '@mui/material';
import { Colors } from './colors';

const defaultTheme = createTheme({});
const { breakpoints, palette } = defaultTheme;

const font = "'Lato', GilroyBold";

declare module '@mui/material/styles' {
  interface Palette {
    magentaPink: PaletteColorOptions;
    deepYellow: PaletteColorOptions;
    violet: PaletteColorOptions;
    green: PaletteColorOptions;
    neutral: PaletteColorOptions;
  }
  interface PaletteOptions {
    magentaPink: PaletteColorOptions;
    deepYellow: PaletteColorOptions;
    violet: PaletteColorOptions;
    green: PaletteColorOptions;
    red: PaletteColorOptions;
    neutral: PaletteColorOptions;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/Pagination' {
  interface PaginationPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/PaginationItem' {
  interface PaginationItemPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/InputLabel' {
  interface InputLabelPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    red: true;
    neutral: true;
  }
}

export const baseTheme = createTheme({
  typography: {
    fontFamily: font,
    h1: {
      fontFamily: 'GilroyBold',
      fontSize: '64px',
      lineHeight: '80px',
      [breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: 'normal',
      },
    },
    h2: {
      fontFamily: 'GilroyBold',
      fontSize: '48px',
      lineHeight: '64px',
      [breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: 'normal',
      },
    },
    h3: {
      fontFamily: 'GilroyBold',
      fontSize: '32px',
      lineHeight: '48px',
      [breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: 'normal',
      },
    },
    h4: {
      fontFamily: 'GilroyBold',
      fontSize: '24px',
      lineHeight: '36px',
      [breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: 'normal',
      },
    },
    h5: {
      fontFamily: 'GilroyBold',
      fontSize: '20px',
      lineHeight: '30px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
    h6: {
      fontFamily: 'GilroyBold',
      fontSize: '18px',
      lineHeight: '28px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: 'normal',
      },
    },
    subtitle1: {
      fontFamily: 'Lato',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    subtitle2: {
      fontFamily: 'Lato',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '22px',
      [breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    body1: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    body2: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    caption: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      [breakpoints.down('sm')]: {
        fontSize: '10px',
        lineHeight: 'normal',
      },
    },
    overline: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'none',
      [breakpoints.down('sm')]: {
        fontSize: '10px',
        lineHeight: 'normal',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato',
          textTransform: 'none',
          borderRadius: '12px',
          padding: '12px 24px',
          boxShadow: 'unset',
          ':hover': {
            boxShadow: 'unset',
          },
        },
        startIcon: {
          marginLeft: '-8px',
          pointerEvents: 'none',
        },
        endIcon: {
          marginRight: '-8px',
          pointerEvents: 'none',
        },
      },
      variants: [
        // Size Variants
        {
          props: { size: 'small' },
          style: {
            height: '40px',
            fontSize: '12px',
            fontWeight: '700',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '44px',
            fontSize: '14px',
            fontWeight: '700',
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: '48px',
            fontSize: '16px',
            fontWeight: '700',
          },
        },

        // Disabled Variants
        {
          props: { variant: 'contained', disabled: true },
          style: {
            backgroundColor: `${Colors.gray} !important`,
            color: `${Colors.midLightGray} !important`,
          },
        },
        {
          props: { variant: 'outlined', disabled: true },
          style: {
            opacity: 0.38,
            borderColor: `${Colors.gray} !important`,
            color: `${Colors.midDarkGray} !important`,
          },
        },

        // Outlined Variants
        {
          props: { variant: 'outlined', color: 'magentaPink' },
          style: {
            borderColor: Colors.magentaPink,
            ':hover': {
              borderColor: Colors.magentaPink,
              backgroundColor: Colors.magentaPinkLight,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'deepYellow' },
          style: {
            borderColor: Colors.deepYellow,
            ':hover': {
              borderColor: Colors.deepYellow,
              backgroundColor: Colors.deepYellowLight,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'violet' },
          style: {
            borderColor: Colors.violet,
            ':hover': {
              borderColor: Colors.violet,
              backgroundColor: Colors.violetLight,
            },
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          font: 'Lato',
          fontSize: '14px',
          color: 'var(--darkGray)',
          fontWeight: '600',
          backgroundColor: 'var(--white)',
        },
        input: {
          '::placeholder': {
            opacity: '1 !important',
            fontSize: '12px',
            color: 'var(--midLightGray)',
            fontWeight: '400',
          },
        },
      },
      variants: [
        {
          props: { color: 'magentaPink' },
          style: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--lightGray)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--magentaPinkDark)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--magentaPink)',
              borderWidth: 1,
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            opacity: 0.5,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--lightGray) !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--lightGray)',
            },
          },
        },
      ],
    },
    MuiPaginationItem: {
      variants: [
        {
          props: { color: 'magentaPink' },
          style: {
            color: Colors.darkGray,
            borderColor: Colors.lightGray,
            fontSize: 14,
            ':hover': {
              backgroundColor: Colors.magentaPinkLight,
              borderColor: Colors.magentaPinkLight,
            },
            '&.Mui-selected': {
              backgroundColor: Colors.magentaPink,
              borderColor: Colors.magentaPink,
              color: Colors.white,
            },
            '&.Mui-selected:hover': {
              backgroundColor: Colors.magentaPinkDark,
              borderColor: Colors.magentaPinkDark,
              color: Colors.white,
            },
          },
        },
        {
          props: { type: 'end-ellipsis' },
          style: {
            ':hover': {
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            },
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'var(--body)',
          zIndex: 1,
        },
        select: {
          display: 'flex',
          alignItems: 'center',
          paddingRight: '16px !important',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 0,
          [breakpoints.down('sm')]: {
            boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.12)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          padding: '12px',
          height: 'auto',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            color: 'var(--darkGray)',
            fontWeight: '600',
            fontSize: '12px !important',
          },
          padding: '0 4px',
        },
        label: {
          marginTop: '2px',
          color: 'var(--midDarkGray)',
          fontSize: '12px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.midLightGray,
        },
      },
      variants: [
        {
          props: { color: 'error' },
          style: {
            color: Colors.red,
          },
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          height: '6px',
        },
        rail: {
          borderRadius: '0px',
          color: 'var(--antiFlashWhite)',
          height: '6px',
          opacity: '1',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { elevation: 1 },
          style: {
            boxShadow: '-0.5px 0.5px 2px 0px rgba(0, 0, 0, 0.24) !important',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 0,
          [breakpoints.down('sm')]: {
            maxWidth: 260,
            padding: '20px !important',
          },
        },
        tooltipPlacementBottom: {
          [breakpoints.down('sm')]: {
            marginTop: '0 !important',
          },
        },
        tooltipPlacementTop: {
          [breakpoints.down('sm')]: {
            marginBottom: '0 !important',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--antiFlashWhite)',
          height: '43px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: 'none',
          backgroundColor: 'var(--antiFlashWhite)',
          // caption typography styles
          fontFamily: 'Lato',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '18px',
          color: 'var(--darkGray)',
          [breakpoints.down('sm')]: {
            fontSize: '10px',
            lineHeight: 'normal',
          },
        },
        root: {
          width: 'auto',
          textAlign: 'center',
          padding: '12px',
          borderBottom: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: 'var(--antiFlashWhite)',
        },
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: Colors.white,
          },
          '&:nth-of-type(even)': {
            backgroundColor: Colors.secondaryLight,
          },
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            width: '4px !important',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          padding: 'unset !important',
        },
        icon: {
          marginInlineEnd: '4px',
          padding: 'unset !important',
          color: 'unset !important',
        },
        root: {
          padding: '24px',
          [breakpoints.down('sm')]: {
            padding: '16px 8px',
          },
        },
      },
      variants: [
        {
          props: { severity: 'success' },
          style: {
            backgroundColor: 'rgba(59, 183, 126, 0.1)',
            color: Colors.green,
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: Colors.midLightGray,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '16px 12px',
          '&:hover': {
            backgroundColor: 'var(--secondaryLight) !important',
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--secondaryLight) !important',
            '&:hover': {
              backgroundColor: 'var(--secondaryLight) !important',
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          '&:before': {
            display: 'none',
          },
        },
        //@ts-ignore
        '&.Mui-expanded': {
          minHeight: '64px !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 24px !important',
          [breakpoints.down('sm')]: {
            padding: '0 16px !important',
          },
        },
        content: {
          margin: '16px 0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 24px 0 !important',
          borderLeft: '6px solid var(--deepYellow)',
          marginLeft: '24px',
          marginBottom: '24px',
          [breakpoints.down('sm')]: {
            padding: '0px 16px 0 !important',
            marginLeft: '16px',
            marginBottom: '16px',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--lightGray)',
        },
      },
    },
  },
  palette: {
    magentaPink: palette.augmentColor({
      color: {
        main: Colors.magentaPink,
        dark: Colors.magentaPinkDark,
        light: Colors.magentaPinkLight,
        contrastText: Colors.white,
      },
    }),
    deepYellow: palette.augmentColor({
      color: {
        main: Colors.deepYellow,
        dark: Colors.deepYellowDark,
        light: Colors.deepYellowLight,
        contrastText: Colors.white,
      },
    }),
    violet: palette.augmentColor({
      color: {
        main: Colors.violet,
        dark: Colors.violetDark,
        light: Colors.violetLight,
        contrastText: Colors.white,
      },
    }),
    green: palette.augmentColor({
      color: {
        main: Colors.green,
        dark: Colors.greenDark,
        light: Colors.greenLight,
        contrastText: Colors.white,
      },
    }),
    red: palette.augmentColor({
      color: {
        main: Colors.red,
        dark: Colors.redDark,
        light: Colors.redLight,
        contrastText: Colors.white,
      },
    }),
    neutral: palette.augmentColor({
      color: { main: Colors.midDarkGray, dark: Colors.antiFlashWhite, contrastText: Colors.white },
    }),
  },
});
